import React, { useState, useEffect } from 'react';
import ChatBot from 'react-simple-chatbot';
import { Segment } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ThemeProvider } from "styled-components";
import { Icon } from 'semantic-ui-react';
import ChatIcon from './images/message-write-svgrepo-com.svg'; // Update the path to your SVG file
import ChatIconB from './images/customer-support-chat-2-svgrepo-com.svg'; // Update the path to your SVG file
import CloseIcon from './images/close-small-svgrepo-com.svg'; // Update the path to your SVG file
import logo from './static/img/rectangle-20.png';
import userAvatarPlaceholder from './images/default-avatar-profile-icon-vector-social-media-user-image-700-205124837-removebg-preview.png'; // Import an avatar placeholder image
import useApi from './useApi'; // Import the custom hook


function SimpleChatbot() {
  const userName = localStorage.getItem('FirstName');
  const userImage = localStorage.getItem('picture');
  let userEmail = localStorage.getItem('email');
  const { sendRequest} = useApi();

  const userAvatar = userImage || userAvatarPlaceholder;
    
    const theme = {
        background: '#f5f8fb',
        fontFamily: 'Helvetica Neue',
        headerBgColor: '#4B9385', // Green color for header
        headerFontColor: '#fff',
        headerFontSize: '15px',
        botBubbleColor: '#4B9385', // Green color for bot messages
        botFontColor: '#fff',
        userBubbleColor: '#4B9385',
        userFontColor: '#fff',
    };


    let emailSteps = [];
    if (!userEmail) {
        emailSteps = [
            {
                id: 'askEmail',
                message: 'What email address should we get back to?',
                trigger: 'emailInput',
            },
            {
                id: 'emailInput',
                user: true,
                validator: (value) => {
                    if (/^\S+@\S+\.\S+$/.test(value)) {
                        localStorage.setItem('email', value);
                        userEmail = value
                        return true;
                    } else {
                        return 'Please enter a valid email address.';
                    }
                },
                trigger: 'endConversation',
            }
        ];
    }

    // Combine all steps including the main conversation and email steps
    const steps = [
        // Initial steps for asking the user's name if not known
        ...(!userName ? [
            {
                id: 'askName',
                message: 'Hello! What is your name?',
                trigger: 'nameInput',
            },
            {
                id: 'nameInput',
                user: true,
                validator: (value) => {
                    if (value.trim().length > 0) {
                        localStorage.setItem('FirstName', value);
                        return true;
                    } else {
                        return 'Please enter a valid name.';
                    }
                },
                trigger: 'greetUser',
            }
        ] : []),
        {
            id: 'greetUser',
            message: `Hello ${userName || '{previousValue}'}, how can I assist you today?`,
            trigger: 'options',
        },
        // Main conversation options
        {
            id: 'options',
            options: [
                { value: 'support', label: 'Support', trigger: 'supportInput' },
                { value: 'reportBug', label: 'Report Bug', trigger: 'bugInput' },
                { value: 'payment', label: 'Payment', trigger: 'paymentInput' },
                { value: 'featureRequest', label: 'Request for Feature', trigger: 'featureInput' },
                // Add more options as needed
            ],
        },
        // Handlers for each option
        {
            id: 'supportInput',
            message: 'Please enter your support query or message.',
            trigger: 'userMessage',
        },
        {
            id: 'bugInput',
            message: 'Please describe the bug you encountered.',
            trigger: 'userMessage',
        },
        {
            id: 'paymentInput',
            message: 'Please enter your payment-related query.',
            trigger: 'userMessage',
        },
        {
            id: 'featureInput',
            message: 'Please describe the feature you would like to request.',
            trigger: 'userMessage',
        },
        {
            id: 'userMessage',
            user: true,
            trigger: userEmail ? 'endConversation' : 'askEmail',
        },
        // Email steps if the user's email is not known
        ...emailSteps,
        {
            id: 'endConversation',
            message: 'Thank you for your input! We will get back to you shortly.',
            end: true,
        },
    ];
  
  // Moved outside useEffect

  const handleEnd = async ({ renderedSteps }) => {
    let message = "";

    for (let i = 0; i < renderedSteps.length; i++) {
      const step = renderedSteps[i];
  
      // Determine if the message is from the bot or the user based on the index
      const sender = i % 2 === 0 ? "Bot" : "User";
  
      if (step.message) {
        message += `${sender}: ${step.message}\n`;
      }
    }
  

    // Additional user data
    const userData = {
        name: userName ,
        email: userEmail,
        message
    };

    async function sendSupportEmail(data) {      
      try {
        const response = await sendRequest('/send_support_email', data);  

        const result =  response.data;
        console.log("Success:", result);
      } catch (error) {
        console.error("Error:", error);
      }
    }
    
    sendSupportEmail(userData);
};

  return (
    <ThemeProvider theme={theme}>
      <div style={{ position: 'fixed', bottom: '60px', right: '20px', zIndex: 9999999999999999 }}>
        <ChatBot 
          steps={steps} 
          handleEnd={handleEnd} 
          botAvatar={logo}
          userAvatar={userAvatar}
        />
      </div>
    </ThemeProvider>
  );
}


const CustomIcon = ({ showChatbot, onClick }) => {
  return (
    <img
      src={showChatbot ? CloseIcon : ChatIconB}
      aria-label={showChatbot ? "Close chatbot" : "Open chatbot"}
      alt="Chatbot Icon"
      onClick={onClick}
      style={{
        position: 'fixed',
        bottom: '10px',
        right: '20px',
        zIndex: 9999999999999999,
        cursor: 'pointer',
        width: '65px', // Adjust the size as needed
        height: '65px' // Adjust the size as needed
      }}
    />
  );
};

function ChatbotToggle() {
  const [showChatbot, setShowChatbot] = useState(false);

  const toggleChatbot = () => {
    setShowChatbot(!showChatbot);
  };

  return (
    <>
      <CustomIcon 
        showChatbot={showChatbot}
        onClick={toggleChatbot}
      />
      {showChatbot && <SimpleChatbot />}
    </>
  );
}

export default ChatbotToggle;