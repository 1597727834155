import {React,useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Protect from './protect';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Ue5 from './ue5';
import Header from './header';
import Signup from './sigh_up';
import Login from './log_in';
import Dashboard from './Dashboard';
import ForgotPassword from './ForgotPassword';
import { AuthProvider } from './AuthContext';
import PricingComponent from './pricing';
import CheckoutComponent from './checkout';
import InfoComponent from './info';
import CVEditor from './CVEditor';
import CodeEditor from './CodeEditor';
import WebRTCComponent from './webrtc';
import useShouldShowHeader from './useShouldShoeHeader';
import ViuePageInterviue from './interviuViewer';
import EmotionAnalysis from './videoEmotion';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import WebcamCapture from './webcame_profile_capture';
import EditDashbored from './edit_dashbored'
import SimpleChatbot from './chatbot';
import Home from './home';
import Footer from './footer';
import ContactPage from './Contact';
import AboutPage from './AboutPage'; // Make sure the path is correct
import CandidateSearch from './CanidateSearch'
import CompanySignup from './CompanySignup';
import LandingPageHRs from './LandingPageHRs';
import NotFoundPage from './NotFoundPage404';
import PageTracker from './PageTracker'; // Import the tracking component

const root = createRoot(document.getElementById('root'));

function AppWithHeader() {
  const shouldShowHeader = useShouldShowHeader();

  return ( 
    <>
      <PageTracker />
      {shouldShowHeader && <Header />}
      <Routes>
        <Route path="*" element={<NotFoundPage />} /> {/* Add this line for the 404 page */}
        <Route path="/" element={<App />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/CompanySignup" element={<CompanySignup />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/Ue5" element={<Protect element={<Ue5 />} />} />
        <Route path="/dashboard" element={<Protect element={<Dashboard CandidateSearch={false} />} />} />
        <Route path="/pricing" element={<PricingComponent />} />
        <Route path="/checkout" element={<Protect element={<CheckoutComponent />} />} />
        <Route path="/info" element={<Protect element={<InfoComponent />} />} />
        <Route path="/CVEditor" element={<Protect element={<CVEditor />} />} />
        <Route path="/CodeEditor" element={<Protect element={<CodeEditor />} />} />
        <Route path="/iframe" element={<Protect element={<WebRTCComponent />} />} />
        <Route path="/interviuViewer" element={<Protect element={<ViuePageInterviue />} />} />
        <Route path="/EditDashbored" element={<Protect element={<EditDashbored />} />} />
        <Route path="/Contact" element={<ContactPage />} />
        <Route path="/About" element={<AboutPage />} />
        <Route path="/LandingPageHRs" element={<LandingPageHRs />} />
        <Route path="/CandidateSearch" element={<Protect element={<CandidateSearch />} />} />
      </Routes>
      {shouldShowHeader && <SimpleChatbot />}
      {shouldShowHeader && <Footer />}

    </>
  );
}

root.render(
  <Router>
    <AuthProvider>
        <AppWithHeader />
    </AuthProvider>
  </Router>
);

reportWebVitals();