import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './AuthContext';
import styles from './styles_css/Header.module.css'; // Import the CSS module
import logo from './static/img/rectangle-20.png';
import userAvatarPlaceholder from './images/default-avatar-profile-icon-vector-social-media-user-image-700-205124837-removebg-preview.png'; // Import an avatar placeholder image

const LogoutButton = () => {
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return <button className={styles.logoutButton} onClick={handleLogout}>Logout</button>;
};

function Header() {
  const { logout } = useAuth(); // Access the logout function from AuthContext
  const [role, setrole] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const location = useLocation();
  const [headerClass, setHeaderClass] = useState(styles.header);
  const [userProfile, setUserProfile] = useState({
    name: '', // You can set a default name
    avatarUrl: '', // Use the placeholder image by default
  });
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const authUlClass = isLoggedIn ? styles.authUl : `${styles.authUl} ${styles.marginLeftAdjust}`;

const toggleMenu = () => {
  setIsMenuOpen(!isMenuOpen);
};

useEffect(() => {
  const isMobileOrTablet = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const screenWidth = window.innerWidth;
  
    // Define width thresholds for mobile and tablet devices
    const mobileWidthThreshold = 768;
  
    // Check if it's a mobile device by user agent and screen width
    if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent) || screenWidth < mobileWidthThreshold) {
      return false;
    }
  
    return true;
  };
  setIsMenuOpen(isMobileOrTablet());

}, []);

useEffect(() => {
  // Retrieve user information from local storage
  let getPicture = localStorage.getItem('picture');
  let getFirstName = localStorage.getItem('FirstName') || '';
  setrole(localStorage.getItem('role'));

  // Explicitly check if getPicture is 'null' (as a string)
  if (getPicture === 'null' || getPicture === null) {
    getPicture = userAvatarPlaceholder;
  }

  // Set user profile with retrieved or default values
  setUserProfile({
    name: getFirstName,
    avatarUrl: getPicture
  });

  // Retrieve access token
  const token = localStorage.getItem('access_token');

  // Set isLoggedIn based on whether the token exists
  setIsLoggedIn(!!token);

  // Consider handling redirection here based on the isLoggedIn state
}, []);

  const handleScroll = () => {
    const top = window.scrollY < 50; // Adjust this value as needed
    const isHomePage = location.pathname === '/' || location.pathname === '/ue5'; // Check if it's the home page

    if (isHomePage && top) {
      setHeaderClass(styles.transparentHeader);
    } else {
      setHeaderClass(styles.header);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, [location, isTop]); // Add location to the dependency array


  return (
    <header className={headerClass}>
      <nav className={styles.nav}>
      <div className={styles.hamburger} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <a href="/"><img src={logo} className={styles.logo} alt="Logo" /></a>
        {isMenuOpen && (
        <ul className={styles.ul}>
          <li className={styles.li}><Link to="/" className={styles.link}>Home</Link></li>
          {role !== 'admin' && (
          <li className={styles.li}><Link to="/pricing" className={styles.link}>Pricing</Link></li>
          )}
          <li className={styles.li}><Link to="/contact" className={styles.link}>Contact</Link></li>
          <li className={styles.li}><Link to="/about" className={styles.link}>About</Link></li>
          {!isLoggedIn && (
          <li className={styles.li}><Link to="/LandingPageHRs" className={styles.link}>Evior for HR's</Link></li>
          )}

          {isLoggedIn && (
            <>
              <li className={styles.li}><Link to="/Dashboard" className={styles.link}>Dashboard</Link></li>
              {role !== 'admin' && <li className={styles.li}><Link to="/info" className={styles.link}>Start</Link></li>}
              {(role === 'admin' || role === 'owner') && <li className={styles.li}><Link to="/CandidateSearch" className={styles.link}>Candidate Search</Link></li>}            </>
          )}
        </ul>
        )}

        <ul className={`${authUlClass} ${styles.userProfileContainer}`}>
          {!isLoggedIn ? (
            <>
              {location.pathname === '/LandingPageHRs' ? (
                <li className={styles.li}>
                  <Link to="/CompanySignup" className={`${styles.link} ${styles.signupButton}`}>Company Signup</Link>
                </li>
              ) : (
                <li className={styles.li}>
                  <Link to="/Signup" className={`${styles.link} ${styles.signupButton}`}>Signup</Link>
                </li>
              )}
              <li className={styles.li}><Link to="/Login" className={`${styles.link} ${styles.loginButton}`}>Login</Link></li>  
            </>

          ) : (
            <>
              <li className={styles.userProfile}>
                <span className={styles.userName}>Hello {userProfile.name}!</span>
                <img
                  src={userProfile.avatarUrl}
                  alt={userProfile.name}
                  className={styles.userAvatar}
                />
              </li>
              <li className={styles.li}><LogoutButton /></li> {/* Logout button is here */}
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;