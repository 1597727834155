import React, { useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import axios from 'axios';
import styles from './styles_css/checkout.module.css'; // Import the stylesheet
import elipse10 from './static/img/ellipse-10.png'
import { Helmet } from 'react-helmet';
import useApi from './useApi'; // Import the custom hook

const CheckoutComponent = ({ email }) => {
  const { sendRequest} = useApi();

  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);
  const [photourl, setphotourl] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const credits = searchParams.get('credits');
  const discountAmount = searchParams.get('discountAmount');
  const cartItems = searchParams.get('cart');
  const cartItemsArray = JSON.parse(cartItems); // Parse the JSON string into an array
  const cartItemsString = cartItemsArray.join(', ');
  const paypalOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID
  };

  useEffect(() => {
    if (cartItems === 'One HR Interview') {
        setphotourl(elipse10);
    }
    if (cartItems === 'Technical Interview') {
      setphotourl(elipse10);
    }
    if (cartItems === 'HR + Technical Interview') {
      setphotourl(elipse10);
    }
    if (cartItems === 'CV from Interview') {
      setphotourl(elipse10);
    }
    if (cartItems === 'Technical Test') {
      setphotourl(elipse10);
    }
    if (cartItems === 'Technical Interview + Technical Test') {
      setphotourl(elipse10);
    } 
    else {
        setphotourl(elipse10);
    }
  }, [cartItems]);

  const handleApprove = async (data, actions) => {
    try {
        const order = await actions.order.capture();
        console.log('Order:', order);
        setPaidFor(true);

        // Prepare the data to send
        const orderData = {
            order: order, 
            email: email,
        };

      // Send a POST request to your Flask API
      const response = await sendRequest('/get-profile-picture', orderData);
      console.log('Order added:', response.data);
      window.location.href = `info`;   

    } catch (error) {
        setError(error);
        alert('PayPal Checkout onError', error);
    }
};


return (
  <div className={styles.checkoutPage}>
    <Helmet>
      <title>Checkout - Your Company Name</title>
      <meta name="description" content="Complete your purchase securely." />
    </Helmet>

    <div className={styles.backgroundImageContainer}>
      <PayPalScriptProvider options={paypalOptions}>
        <div className={styles.checkoutContainer}>
          <h1 className={styles.heading}>Checkout</h1>
          <h2 className={styles.total}>Your Total is: ${credits}</h2>
          
          <img
            className={styles.productIMG}
            alt="Product Visual Representation"
            src={photourl}
          />
          {discountAmount > 0 && (
            <h2 className={styles.total}>Your Total Discount: %{discountAmount}</h2>
          )}

          <h2 className={styles.total}>Your Cart: {cartItemsString}</h2>
          
          <div className={styles.paypalButtonContainer}>
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      description: cartItemsString,
                      amount: {
                        currency_code: 'USD',
                        value: credits,
                      },
                    },
                  ],
                });
              }}
              onApprove={handleApprove}
              onCancel={() => { console.log('Payment Cancelled'); }}
              onError={(err) => {
                setError(err);
                console.error('PayPal Checkout onError', err);
              }}
            />
          </div>

          {paidFor && <p className={styles.thankYouMessage}>Thank You for your purchase!</p>}
          {error && <p className={styles.error}>Error: {error.message}</p>}
        </div>
      </PayPalScriptProvider>
      <img
        className={styles.bigimg}
        alt="Decorative background"
        src="https://cdn.animaapp.com/projects/656fb61af61393768a5cfd60/releases/657368b514ce421528c96c87/img/rectangle-23.png"
      />
    </div>
  </div>
);
}

export default CheckoutComponent;