import React, { useEffect } from 'react';
import axios from 'axios';
import ReactGA from "react-ga4";

const GoogleLoginButton = () => {
  function handleLoginResponse(response) {
    console.log("jwt google token: ", response.credential);

    // Define the headers, including the Google JWT token
    const headers = {
      'x-api-key': process.env.REACT_APP_API_KEY,
      'AuthorizationToken': response.credential
    };

    // Define the body of the request (if needed)
    const body = {};

    // Make the POST request to your server
    axios.post(process.env.REACT_APP_BASE_URL + '/GoogleAuth', body, { headers: headers, withCredentials:true })
      .then(response => {
        // Handle the response
        console.log('Success:', response.data);
        const data = response.data
        if (data.message === 'Login successful') {
          // Store the token in local storage
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('role', data.role);
          localStorage.setItem('email', data.email);
          localStorage.setItem('picture', data.picture);
          localStorage.setItem('FirstName', data.FirstName);
  
          ReactGA.event({
            category: 'User',
            action: 'Login',
            label: 'Login Successful',
            role: data.role, // Custom dimension for User Role
          });
            
          const previousUrl = sessionStorage.getItem('previous_url');
          if (previousUrl && previousUrl != `/Login`) {
            // Use the previousUrl as needed, e.g., for redirection
            console.log("previousUrl:",previousUrl)
            window.location.href = previousUrl;
          } else {
            console.log("previousUrl:",'/dashboard')
          // Redirect the user to the dashboard or another page
          window.location.href = '/dashboard'; // Replace with the desired URL
          
          }
        }
      })
      .catch(error => {
        // Handle errors
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    /* global google */
    if (window.google) {
    google.accounts.id.initialize({
      client_id: "15695878080-rpi1kv6r9mmnsvtbb74sn49v84kda1h5.apps.googleusercontent.com",
      callback: handleLoginResponse
    });

    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      { theme: "outline", size: "large" }
    );
    }
  }, []);

  return (
    <div id='signInDiv'></div>
  );
};

export default GoogleLoginButton;
