import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ProfilePictureUploader from './webcame_profile_capture';
import linkedInIcon from './images/LinkedIn_icon.svg';
import axios from 'axios';
import styles from './styles_css/EditDashboard.module.css'; // Importing the styles
import { Helmet } from 'react-helmet';

const EditProfilePage = ({ email }) => {
    const location = useLocation();
  
    // Parse the URL query parameters
    const query = new URLSearchParams(location.search);
    const profilePicture = query.get('profilePicture');
    const fullName = query.get('fullName');
  
    // Split the fullName into first name and last name, and set them as initial state
    const fullNameParts = fullName ? fullName.split(' ') : ['', ''];
    const [firstName, setFirstName] = useState(fullNameParts[0]);
    const [lastName, setLastName] = useState(fullNameParts[1]);
    const [linkedIn, setLinkedIn] = useState('');
    const [PublicProfile, setPublicProfile] = useState(false);

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleLinkedInChange = (event) => {
        setLinkedIn(event.target.value);
    };

    const toggleSwitch = () => {
        setPublicProfile(!PublicProfile);
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
  
        const apiUrl = 'http://127.0.0.1:7777/updateProfile'; // Replace with your API endpoint
        const payload = {
            email,
            firstName,
            lastName,
            linkedIn,
            PublicProfile
        };
  
        try {
            const response = await axios.post(apiUrl, payload);
            console.log('Success:', response.data);
            // Handle success scenario
        } catch (error) {
            console.error('Error:', error);
            // Handle error scenario
        }
    };

    const structuredData = {
        "@context": "http://schema.org",
        "@type": "Person",
        "name": `${firstName} ${lastName}`,
        "url": window.location.href,
        "sameAs": linkedIn // Assuming this is a URL to the person's LinkedIn profile
    };


    return (
        <div className={styles.EditDashboredPage}>
            <Helmet>
                <title>Edit Profile - YourSiteName</title>
                <meta name="description" content="Edit your professional profile to keep your information up-to-date." />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
        <div className={styles.container}>
            <h1 className={styles.title}>Edit Profile</h1>
            <form onSubmit={handleSubmit} className={styles.formdata}>
                <div className={styles.formGroup}>
                    <label className={styles.label}>First Name:</label>
                    <input className={styles.inputField} type="text" value={firstName} onChange={handleFirstNameChange} />
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.label}>Last Name:</label>
                    <input className={styles.inputField} type="text" value={lastName} onChange={handleLastNameChange} />
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.label}>
                    <img src={linkedInIcon} alt="LinkedIn img" style={{ width: '20px', height: '20px' }} />
                        LinkedIn:
                    </label>
                    <input className={styles.inputField} type="text" value={linkedIn} onChange={handleLinkedInChange} />
                    <label className={styles.label}>Public profile:</label>
                    <label className={styles.switch}>
                    <input
                        type="checkbox"
                        checked={PublicProfile}
                        onChange={toggleSwitch}
                    />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                    </label>
                    <span className={styles.stateText } >{PublicProfile ? 'Enabled' : 'Disabled'}</span>
                    <button type="submit" className={styles.button}>Update Profile</button>
                </div>
                <ProfilePictureUploader 
                    email={email}
                    base64ProfileImg={profilePicture}
                />
            </form>
        </div>
        </div>

    );
};

export default EditProfilePage;
