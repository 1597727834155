import React, { useState, useEffect } from 'react';
import axios from 'axios';

const defaultEmotions = {
  'Emotional Stability': ['Admiration', 'Adoration', 'Calmness', 'Contentment', 'Relief', 'Satisfaction'],
  'Stress Handling': ['Anxiety', 'Fear', 'Distress', 'Doubt', 'Sadness', 'Shame'],
  'Openness': ['Aesthetic Appreciation', 'Contemplation', 'Interest', 'Nostalgia'],
  'Energy Level': ['Amusement', 'Excitement', 'Ecstasy', 'Enthusiasm'],
  'Aggressiveness': ['Anger', 'Contempt', 'Disgust', 'Horror'],
  'Happiness': ['Joy', 'Love', 'Romance'],
  'Introversion': ['Calmness', 'Contentment', 'Tranquil', 'Nostalgia', 'Sadness', 'Shame'],
  'Extroversion': ['Excitement', 'Ecstasy', 'Happiness', 'Enthusiasm'],
  'Assertiveness': ['Determination', 'Realization', 'Triumph', 'Sarcasm'],
  'Surprise (positive)': ['Surprise (positive)'],
  'Sympathy': ['Sympathy'],
  'Tiredness': ['Tiredness'],
  'Awkwardness': ['Awkwardness'],
  'Boredom': ['Boredom'],
  'Concentration': ['Concentration'],
  'Confusion': ['Confusion'],
  'Craving': ['Craving'],
  'Desire': ['Desire'],
  'Disappointment': ['Disappointment'],
  'Envy': ['Envy'],
  'Guilt': ['Guilt'],
  'Pride': ['Pride'],
  // Add more personality traits as needed
};

function EmotionBox() {
  const [emotions, setEmotions] = useState([]);
  const [userMood, setUserMood] = useState('');
  const [emotionMap, setEmotionMap] = useState({});

  const calculateUserMood = (emotionsData) => {
    const moodScores = {};
    
    emotionsData.forEach(emotion => {
      const { name, score } = emotion;
      moodScores[name] = score;
    });

    return moodScores;
  };

  const calculateEmotionMap = (moodScores) => {
    const maxScores = {};
    let closestEmotions = [];
    
    // Find the highest score for each emotion group
    Object.entries(defaultEmotions).forEach(([groupName, emotionGroup]) => {
      maxScores[groupName] = emotionGroup.reduce((max, emotion) => Math.max(max, moodScores[emotion] || 0), 0);
    });

    // Find emotions that are closest to the user's mood
    Object.entries(defaultEmotions).forEach(([groupName, emotionGroup]) => {
      if (maxScores[groupName] > 0) {
        emotionGroup.forEach((emotion) => {
          if (moodScores[emotion] && moodScores[emotion] === maxScores[groupName]) {
            closestEmotions.push(emotion);
          }
        });
      }
    });

    setEmotionMap(maxScores);
    return closestEmotions;
  };

  useEffect(() => {
    axios.get('http://127.0.0.1:7777/GetEmotions')
      .then(response => {
        const emotionsData = response.data[0]?.results?.predictions[0]?.models?.face?.grouped_predictions[0].predictions[0].emotions || [];
        const moodScores = calculateUserMood(emotionsData);
        const closestEmotions = calculateEmotionMap(moodScores);

        setUserMood(closestEmotions.join(', '));
        setEmotions(emotionsData);
      })
      .catch(error => {
        console.error('Error fetching emotions:', error);
      });
  }, []);

  return (
    <div className="emotion-box-container">
      <h1>User's Current Mood: {userMood}</h1>
      <div className="emotions-box">
        {Object.entries(emotionMap).map(([groupName, score]) => (
          <div key={groupName}>
            <h2>{groupName}</h2>
            <p>Score: {score.toFixed(2)}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EmotionBox;
