import React, { useEffect ,useState} from "react";
import { Link } from 'react-router-dom';
import Chart from "chart.js/auto"; // Import Chart.js
import YouTube from 'react-youtube';
import ContactPage from "./Contact";
import { Helmet } from 'react-helmet';

import aws from './static/img/rectangle-4.png'
import Tesla from './static/img/rectangle-5.png'
import cisco from './static/img/rectangle-6.png'
import intel from './static/img/rectangle-7.png'
import google from './static/img/rectangle-8.png'
import elipse10 from './static/img/ellipse-10.png'
import headerimg from './images/Frame 37.png'
import ClipsFromInterviewIMG from './images/Group 38 (2).png'

import graphstyle from './styles_css/interviuViewer.module.css'
import styles from './styles_css/LandingPageHRs.module.css'
import HomePageStyles from './styles_css/home.module.css'

function LandingPageHRs() {

  const [products] = useState([
    {
      name: 'One HR Interview',
      price: 9.99,
      description: 'Personalized Practice: Tailor your practice sessions based on your CV or target a specific companys interview style.Real-world Scenarios: Experience realistic HR interview scenarios, preparing you for a variety of questions and situations. Benefit: Boosts confidence and provides insights on how to present your skills and experiences effectively.',
      image: elipse10
    },
    {
      name: 'Technical Interview',
      price: 50,
      description: 'Customized Sessions: Focus on technical questions relevant to your skill set, derived from your CV or a chosen company.Skill Enhancement: Hone your technical knowledge and problem-solving abilities.Benefit: Increases your chances of impressing potential employers with your technical acumen.',
      image: elipse10
    },
    {
      name: 'HR + Technical Interview',
      price: 100,
      description: 'Integrated Approach: Seamlessly combines HR and technical interview practice.Complete Preparation: Get ready for all aspects of your job interview in one comprehensive package.Benefit: Saves time and provides a well-rounded preparation experience.',
      image: elipse10
    },
    {
      name: 'CV from Interview',
      price: 100,
      description: 'Interactive CV Creation: Generate or enhance your CV through a brief interview session.Stylish and Professional: Your CV will highlight both personal and technical strengths in a compelling format.Added Feature: Include a personal profile and a barcode linking to your online resume showcasing practice interviews.Benefit: Makes your CV stand out to HR professionals, reducing the time to land job interviews.',
      image: elipse10
    },
    {
      name: 'Technical Test',
      price: 25,
      description: 'Tailored Testing: Receive technical tests designed specifically for your CV or target company.Skill Evaluation: Assess and improve your technical prowess.Key Benefit: Prepares you effectively for the technical assessment part of the interview process.',
      image: elipse10
    },
    {
      name: 'Technical Interview + Technical Test',
      price: 75,
      description: 'Tailored Testing: Receive technical tests designed specifically for your CV or target company.Skill Evaluation: Assess and improve your technical prowess.Key Benefit: Prepares you effectively for the technical assessment part of the interview process.',
      image: elipse10
    }
,
  ]);


    const demoGraphData = {
        labels: ["content", "clarity", "structure", "creativity"],
        scores: [80, 90, 70, 85],
      };
    
      const demoImprovements = {
        "content": "The answer lacks specific details about the technical problem encountered and the steps taken to address it.",
        "clarity": "The answer is unclear and difficult to understand. Please try to provide a clearer explanation.",
        "structure": "The answer lacks a clear structure and organization. Please organize the information in a more structured manner.",
        "creativity": "The answer lacks creativity and originality. Try to think of unique solutions or approaches to the problem.",
      };
    
      // Function to render the demo graph
      const renderDemoGraph = () => {
        const ctx = document.getElementById("demoGraph");
    
        if (Chart.getChart(ctx)) {
          Chart.getChart(ctx).destroy();
        }
    
        new Chart(ctx, {
          type: "bar",
          data: {
            labels: demoGraphData.labels,
            datasets: [
              {
                label: "Scores",
                data: demoGraphData.scores,
                backgroundColor: "rgba(224, 194, 188, 0.7)", // rgba color with alpha channel
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                max: 100,
                title: {
                  display: true,
                  text: "Scores",
                },
              },
            },
          },
        });
      };
    
      useEffect(() => {
        // Render the demo graph when the component mounts
        renderDemoGraph();
      }, []);

      const structuredData = {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Evior",
        "url": "http://www.evior.com",
        "logo": "https://www.evior.com/logo.png",
        // Other relevant details
    };

    return (
        <main className={styles.LandingPageHRs}>
              <Helmet>
                <title>Evior - The new Way to find candidates</title>
                <meta name="description" content="Join Evior, Where there is a sea of starts for your team." />
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
            </Helmet>
            <section className={styles.HeroSection}>
                <div className={styles.headerText}>
                    <p className={styles.headerTitle}>
                      The Evior Advantage Making Smart Hiring Decisions
                    </p>
                    <p className={styles.headerContent}>
                    Evior offers certified candidates who undergo thorough evaluations. You receive comprehensive assessment insights, transparent interview excerpts, and detailed reports, all accessible through the Evior barcode. This streamlines the hiring process, enabling confident, data-driven hiring decisions, reducing risks, and ensuring the selection of top talent.
                    </p>
                    <li className={HomePageStyles.linkButton}>
                        <Link to="/Signup" className={`${HomePageStyles.link} ${HomePageStyles.signupButton}`}>Start</Link>
                    </li>
                </div>
                <div className={styles.HeaderImges}>
                    <img
                        alt="Header Backround"
                        src={headerimg}
                        loading="Lazy"
                    />
                </div>
            </section>


          <section className={styles.startSection}>
            <div className={styles.backgroundBlur}></div>
            <div className={styles.startContiner}>
                <p className={styles.demoVideoContiner2P}>Get Started!</p>
                <p className={styles.demoVideoContiner2P}>What are you waiting for its free!</p>
                <li className={HomePageStyles.linkButton}>
                    <Link to="/Signup" className={`${HomePageStyles.link} ${HomePageStyles.signupButton}`}>Start</Link>
                </li>
            </div>
        </section>
          
          <section className={HomePageStyles.ReceivepersonalizedfeedbackContiner4}>
            <div className={HomePageStyles.PersonalizedInterviewTextContent}>
                    <p className={HomePageStyles.ReceivepersonalizedfeedbackTitle}>
                    Candidate Performance Deep Dive Evior's Detailed Reports
                    </p>
                    <p className={HomePageStyles.ReceivepersonalizedfeedbackContent}>

                    Evior's comprehensive reports provide detailed insights into a candidate's performance during assessments. These reports offer a deep understanding of their strengths and weaknesses, aiding in informed hiring decisions. Accessible through the Evior platform, these reports streamline the evaluation process, ensuring you choose the right talent for your organization with confidence.
                </p>
                </div>
                <div className={graphstyle.container}>
                        <h2>Question </h2>
                        <p className={graphstyle.currentQuestiontext}>how many birds </p>
                        <div className={graphstyle.graphcontainer}>
                            <canvas id="demoGraph" className="chart-container"></canvas>
                        </div>
                        <div className={graphstyle.improvementsContainer}>
                            <h3>Improvements:</h3>
                            {Object.entries(demoImprovements).map(([key, value]) => (
                                <p className={graphstyle.improvements} key={key}>
                                {key}: {value}
                                </p>
                            ))}
                            </div>
                </div>
            </section>
            <section className={styles.ClipsFromInterviewSection}>
            <div className={HomePageStyles.PersonalizedInterviewTextContent}>
                    <p className={HomePageStyles.ReceivepersonalizedfeedbackTitle}>
                    Data-Driven Hiring Analyzing Evior's Performance Maps
                    </p>
                    <p className={HomePageStyles.ReceivepersonalizedfeedbackContent}>
                    In the Candidate Profile Page, you'll find an invaluable resource: recorded interviews conducted via Evior. These video recordings offer a comprehensive view of the candidate's interview performance, allowing you to assess their communication skills and overall suitability with clarity. Additionally, you'll have access to performance maps that meticulously analyze their interview responses, providing you with concrete, data-driven insights to inform your hiring decisions.
                </p>
                </div>
                <div className={styles.ClipsFromInterviewIMG}>
                    <img
                        alt="Clips from  Canidates Interview"
                        src={ClipsFromInterviewIMG}
                        loading="lazy"
                    />
                </div>

            </section>
            <section className={styles.ContactSection}>
            <ContactPage/>
            </section>

     </main>

        );
    }
    
    export default LandingPageHRs;