import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import methum from './images/methum.mp4';
import MicBar from './micbar';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Webcam from 'react-webcam';
import styles from './styles_css/ue5.module.css'
import { Helmet } from 'react-helmet';
import useApi from './useApi'; // Import the custom hook

function Ue5({ email }) {
  const { sendRequest} = useApi();
  
  const protocol = window.location.protocol;
  const host = window.location.host;
  const iframeHost = `${protocol}//${host}`;

  const video = 'https://d15nfymfajd7yw.cloudfront.net/content for website/Vidoes of metahuman/metahuma_IDLE1.mp4'
  const very_good_answer = 'https://d15nfymfajd7yw.cloudfront.net/content for website/Vidoes of metahuman/very_good_answer_1.mp4'
  const videoBoxContainerRef = useRef(null);
  const IframRef = useRef(null);
  const sizeContainerRef = useRef(null);
  const [webrtcClientready, setWebrtcClientready] = useState(false);
  const [SentAudioData, setSentAudioData] = useState(false);
  const [canOpenIframe, setCanOpenIframe] = useState(true);
  const [OpenIframe, setOpenIframe] = useState(false);
  const [base64Data, setBase64Data] = useState('');
  const [continue_interview, setContinueInterview] = useState('');
  const [Finished, setFinished] = useState(false);
  const [DontSendMoreAudio, setDontSendMoreAudio] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [ProductIndex, setProductIndex] = useState(false);
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [stopCaptureVideo, setStopCaptureVideo] = useState(false);
  const [sendcallculateUE5, setsendcallculateUE5] = useState(null);
  const [started, setstarted] = useState(true);
  const [LoadingScreen, setLoadingScreen] = useState(false);
  const [LastVideoRecorded, setLastVideoRecorded] = useState([]);
  const [sendvideo, setsendvideo] = useState(false);
  let openTimestamp = null;
  let videoRecordedChunks = [];
  let lastVideoBlob;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const interview_number = searchParams.get('param1');
  const continue_interview_url = searchParams.get('param2');

  const videoSources = [very_good_answer];

  const sendmessegeiframe = () => {
    if (!IframRef.current) return;
    console.log('send message to ');
    IframRef.current.contentWindow.postMessage('messagetoiframe', iframeHost);
  };

  const openIframe = () => {
    if (canOpenIframe) {
      setOpenIframe(true);
    } else {
      console.log('canOpenIframe: ' + canOpenIframe);
      setOpenIframe(true);
    }
  };

  const closeIframe = () => {
    IframRef.current.src = '';
    setShowIframe(false);
    setOpenIframe(false);
    setCanOpenIframe(false);
    setTimeout(() => {
      setCanOpenIframe(true);
    }, 15000);
  };


  const postData = {
    email: email,
    interview_number: interview_number,
  };

    const sendInitialRequest = async () => {
      setstarted(false);
      setLoadingScreen(true);
      const postDatacheck =  postData
      postDatacheck.time_start = new Date().toString();
      console.log(postDatacheck.time_start)
      try {
        const response = await sendRequest('/check_token-first_question', postDatacheck);
        StartCaptureVideo();
        if (response.data.next_question === 'Invalid token') {
          console.log('Invalid token');
          window.location.href = '/Dashboard';
        } else {
          localStorage.setItem('access_token', response.data.access_token);
          setLoadingScreen(false);
          StopCapVideo();
          console.log('POST Request Result:', response.data);
          setProductIndex(response.data.product_index);
          const base64Data = response.data['64base'];
          await playVideo(very_good_answer);          
          await setBase64Data(base64Data);
          await openIframe();
        }
      } catch (error) {
        console.error('Error sending POST request:', error);
      }
    };


 
  useEffect(() => {
    if (continue_interview_url === 'yes') {
      setContinueInterview(true);
    }


    if (webrtcClientready) {
      console.log('WebRTC client is initialized. Do something here.');
      const deduct_tokens = async () => {
        try {
          const requestData = {
            email: email,
            interview_number: interview_number,
          };

          const response = await sendRequest('/deduct_usd_tokens', requestData);

          if (response) {
            const data = await response.data;
            console.log(data)
            if (data.data?.finishedInterviue) {
              console.log("finished")
              setFinished(true);
            }
            console.log(data);
          } else {
            console.error('HTTP request failed with status:', response.status);
          }
        } catch (error) {
          console.error('Error sending HTTP request:', error);
        }
      };

      const intervalId = setInterval(() => {
        deduct_tokens();
      }, 60000);

      return () => clearInterval(intervalId);
    }


  }, [email, webrtcClientready]);

  useEffect(() => {
    if (canOpenIframe && OpenIframe) {
      IframRef.current.src = `${iframeHost}/iframe?isdtp=vw`;
      setCanOpenIframe(false);
      setOpenIframe(false);
    }
  }, [canOpenIframe, OpenIframe]);
  
  
  useEffect(() => {
    const handleMessage = (e) => {
      if (e.origin !== iframeHost) return;
      const messageData = e.data;
      console.log("messageData: ",messageData)
      if (messageData === 'finished') {
        closeIframe();
        setSentAudioData(false);
        // CALCULATE UE5 COST
        const finishedTimestamp = new Date();
        const timeElapsed = (finishedTimestamp - openTimestamp) / 60000; // Time elapsed in minutes
        setsendcallculateUE5(timeElapsed)
        console.log(timeElapsed)
        openTimestamp = null;
      }

      if (messageData === 'open') {
        setWebrtcClientready(true);
        openTimestamp = new Date();
        setTimeout(() => {
          fetchAndSendAudioToIframe(base64Data);
        }, 2000);
      }

      if (messageData === 'audioDataReceived') {
        setSentAudioData(false);
      }
    

      if (messageData === 'showIframe') {
        setShowIframe(true);
      }
    };

  
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [base64Data]);



  const fetchAndSendAudioToIframe = async (audioBase64) => {
    if (!IframRef.current || !audioBase64) {
      return;
    }

    const audioData = {
      type: 'audioData',
      data: audioBase64,
    };

    if (!SentAudioData){
      console.log("SentAudioData: ",SentAudioData)
      IframRef.current.contentWindow.postMessage(audioData, iframeHost);
      setSentAudioData(true);
    }

  };

  function checkResponse(response) {
      const base64Data = response['64base'];
      setBase64Data(base64Data);
      openIframe();
  }


  async function sendAudioToServer(transcript) {
    setsendvideo(false);
    const JsonData = {
        email: email,
        interview_number: interview_number,
        finalTranscript: transcript,
        callculateUE5: sendcallculateUE5,
        ProductIndex: ProductIndex,
        Finished: DontSendMoreAudio
    };

    if (ProductIndex !== 4) {
        console.log("ProductIndex:", ProductIndex);
        const videoBlob = new Blob(recordedChunks, {
            type: 'video/webm',
        });
        setRecordedChunks([]);
        const reader = new FileReader();

        // Use a Promise to wait for the FileReader to finish
        await new Promise((resolve) => {
            reader.onloadend = function() {
                const base64data = reader.result;
                JsonData.videoFile = base64data;
                resolve();  // Resolve the promise after setting the base64 data
            };
            reader.readAsDataURL(videoBlob);
        });
    } else {
        console.log("not sending video");
    }

    try {
        setsendcallculateUE5(null);

        console.log(JsonData);
        const response = await sendRequest('/convert-audio', JsonData);
        console.log(response);

        const transcription = response.data.data;
        
        console.log(transcription.transcription)
        if (!transcript.finishedInterviue) {
            checkResponse(transcription);
        }else{
          setDontSendMoreAudio(true);
          setFinished(true);
          
        }
        console.log('Transcription:', transcription);
    } catch (error) {
        console.error('Error sending audio:', error);
    }
}

  

  const StartCaptureVideo = React.useCallback(async () => {
    if (ProductIndex !== 4){
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm"
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
    }
    else{console.log("not recording video")}
  }, [webcamRef, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(({ data }) => {
    if (data.size > 0 && ProductIndex !== 4) {
      setRecordedChunks((prev) => [...prev, data]);
    }
  }, [setRecordedChunks]);


  const StopCapVideo = React.useCallback(() => {
    if (ProductIndex !== 4){
      mediaRecorderRef.current.stop();
      console.log("stoped recording video")
    }
    else{console.log("not stoping video")}
  }, [mediaRecorderRef, webcamRef]);

  let recordingTimeout = null;

function stopRecordingAfter60Seconds() {
  StopCapVideo();
  clearTimeout(recordingTimeout);
  console.log("Stopped recording video after 60 seconds");
}


    const [isListening, setIsListening] = useState(false);
    const commands = [];
    const {
      transcript,
      resetTranscript,
      listening,
    } = useSpeechRecognition({ commands });

const recordButtonClicked = async () => {
  if (email === "") {
    window.location.href = '/login'; 
  }
  if (isListening) {
      setIsListening(false);
      SpeechRecognition.stopListening();
      console.log(transcript); 

    if (transcript === '') {
      resetTranscript();
      alert('No audio recognized, check the microphone.');
    } else {
      await stopRecordingAfter60Seconds(); // Stop recording video
      if(!DontSendMoreAudio){
        sendAudioToServer(transcript); // Pass the recorded video data
      }
      resetTranscript();
      const selectedVideoSource = getRandomVideoSource(videoSources);
      // Make sure getRandomVideoSource selects a different video source each time
      await playVideo(selectedVideoSource);

      if (Finished) {
        await EndInterviu();
      }
    }
  } else {
    setIsListening(true);
    SpeechRecognition.startListening({
      continuous: true,
      language: 'en-GB',
    });

    // Start recording and set a timeout to stop after 60 seconds
    StartCaptureVideo();
    recordingTimeout = setTimeout(stopRecordingAfter60Seconds, 60000); // 60 seconds
  }
};

  async function EndInterviu() {
    console.log("EndInterviu");
    //Techincal interviu + Technical Test
    if (ProductIndex === 6) {
      const redirectUrl = `/codeEditor?param1=${encodeURIComponent(interview_number)}`;
      window.location.href = redirectUrl;
    }
    else{
    try {
      const postData = {
        email: email,
        interview_number: interview_number,
      };
  
      const response = await sendRequest('/make_pdf_interview', postData);  
      if (response) {
        const data = response.data;
        console.log('POST Request Result:', data);
        await playVideo(methum);

        if (response.status === 409) {
          const redirectUrl = `interviuViewer?interview_number=${encodeURIComponent(interview_number)}`;
          window.location.href = redirectUrl;
        }
  
        if (data.product_index === 4) {
          // Create a URL-encoded query parameter for 'improvement' field
          const redirectUrl = `/cveditor?interview_number=${interview_number}`;
          window.location.href = redirectUrl;
        }
        else{
          const redirectUrl = `interviuViewer?interview_number=${interview_number}`;
          window.location.href = redirectUrl;
        }
      } else {
        console.error('HTTP request failed with status:', response.status);
      }
    } catch (error) {
      console.log(error.status)
      if (error.response && error.response.status === 409) {
        const redirectUrl = `interviuViewer?interview_number=${encodeURIComponent(interview_number)}`;
        window.location.href = redirectUrl;
      }
      console.error('Error sending POST request:', error);
    }
  }
}


  const getRandomVideoSource = (videoSources) => {
    const randomIndex = Math.floor(Math.random() * videoSources.length);
    return videoSources[randomIndex];
  };

  const playVideo = (videoSource) => {
    return new Promise((resolve, reject) => {
      const transactionVideo = document.createElement('video');
      transactionVideo.src = videoSource;
      transactionVideo.autoplay = true;

      transactionVideo.style.position = 'absolute';
      transactionVideo.style.width = '100%';
      transactionVideo.style.height = '100%';
      transactionVideo.style.zIndex = 5;

      if (videoBoxContainerRef.current) {
        videoBoxContainerRef.current.appendChild(transactionVideo);
      } else {
        console.error('Video container not found');
        reject('Video container not found');
        return;
      }

      transactionVideo.addEventListener('ended', () => {
        if (videoBoxContainerRef.current) {
          videoBoxContainerRef.current.removeChild(transactionVideo);
        }
        resolve();
      });

      transactionVideo.addEventListener('error', (error) => {
        if (videoBoxContainerRef.current) {
          videoBoxContainerRef.current.removeChild(transactionVideo);
        }
        reject(error);
      });
    });
  };

  return (
    <div className={styles.App}>
      <Helmet>
        <title>Interactive Interview Platform - Evior</title>
        <meta name="description" content="Engage in an interactive interview experience with our state-of-the-art interviewing platform." />
        {/* Additional meta tags as needed */}
      </Helmet>

      <div className={styles.orientationAlert}>
        <div className={styles.phone}></div>
        Please rotate your device!
      </div>

      {LoadingScreen && (
        <div className={styles.loadingScreen}>
          <div className={styles.loadingSpinner}></div>
        </div>
      )}

      <main ref={videoBoxContainerRef} className={styles.videoBoxContainer}>
        <section className={styles.avatar}>
          <div className={styles.avatarContent}>
            <video id="introVideo" src={video} autoPlay muted loop aria-label="Introduction Video"></video>
            <div
              className={styles.webrtc}
              ref={sizeContainerRef}
              style={{ zIndex: showIframe ? 1 : -1 }}
            >
              <iframe
                ref={IframRef}
                src=""
                title="WebRTC Content"
                width="100%"
                height="100%"
                frameBorder="0"
                aria-label="Interactive Interview Content"
              ></iframe>
            </div>
          </div>
        </section>

        <section className={styles.webcamsection}>
          <Webcam audio={true} ref={webcamRef} muted aria-label="Webcam View"/>
        </section>
      </main>

      <div className={styles.buttonContainer}>
        {started && (
          <button id="start-interview" onClick={sendInitialRequest}>
            Start Interview
          </button>
        )}
        <button
          type="button"
          onClick={recordButtonClicked}
          className={isListening ? styles.recording : ''}
          aria-pressed={isListening}
        >
          {isListening ? 'Stop' : 'Start'}
        </button>
      </div>
    </div>
  );
};

export default Ue5;
