import React, { useEffect ,useState} from "react";
import { Link } from 'react-router-dom';
import Chart from "chart.js/auto"; // Import Chart.js
import YouTube from 'react-youtube';
import { Helmet } from 'react-helmet';
import ToggleSwitch from "./components/General_or_Speecific_Switch";

import aws from './static/img/rectangle-4.png'
import Tesla from './static/img/rectangle-5.png'
import cisco from './static/img/rectangle-6.png'
import intel from './static/img/rectangle-7.png'
import google from './static/img/rectangle-8.png'
import humephotowithPinkBlure from './static/img/Group 39.png'
import elipse1 from './static/img/ellipse-1.png'
import elipse2 from './static/img/ellipse-2.png'
import elipse3 from './static/img/ellipse-3.png'
import elipse10 from './static/img/ellipse-10.png'
import elipse12 from './static/img/ellipse-12.png'
import elipse19stairs from './static/img/rectangle-19.png'
import star from './static/img/star-3.svg'
import line2 from './static/img/line-2.svg';
import graphstyle from './styles_css/interviuViewer.module.css'
import styles from './styles_css/home.module.css'
import productsstyle from './styles_css/InfoComponent.module.css'

function App() {

  const [products, setProducts] = useState([
    {
      name: 'One HR Interview',
      DisplayName: 'HR Interview',
      price: 9.99,
      description: ' Realistic HR Simulation Interviews: Immerse yourself in true-to-life HR interview scenarios that prepare you for a diverse array of questions and situations. Receive detailed feedback at the end of each session, providing valuable insights to enhance your preparation for your next interview.',
      image: elipse10,
      General_or_Specific:'General',
      index:1
    },
    {
      name: 'Technical Interview',
      DisplayName: 'Technical Interview',
      price: 9.99,
      description: 'Master technical interviews with our simulation sessions. Develop problem-solving skills and confidence under pressure. Tackle tough questions, demonstrating expertise and creativity. Benefit from detailed feedback, fine-tuning your performance for real-world success. Stand out in your next interview with enhanced professional competence.',
      image: elipse10,
      General_or_Specific:'General',
      index:2
    },
    {
      name: 'HR + Technical Interview',
      DisplayName: 'HR + Technical Interview',
      price: 100,
      description: 'Integrated Approach: Seamlessly combines HR and technical interview practice.Complete Preparation: Get ready for all aspects of your job interview in one comprehensive package.Benefit: Saves time and provides a well-rounded preparation experience.',
      image: elipse10,
      General_or_Specific:'General',
      index:3
    },
    {
      name: 'Technical Test',
      DisplayName: 'Technical Test',
      price: 25,
      description: 'Ace Your Technical Interviews: Prepare with our comprehensive practice tests, featuring American-style questions and coding projects. Tailor your experience with general tests or opt for company-specific simulations. Elevate your readiness and confidence for that crucial interview!',
      image: elipse10,
      General_or_Specific:'General',
      index:5
    },
    {
      name: 'Technical Interview + Technical Test',
      DisplayName: 'Technical Interview + Technical Test',
      price: 75,
      description: 'Dual-focused program combining technical test practice with interview simulations. Complete Readiness: Prepares you for every aspect of your technical interview journey. Advantage: Streamlines preparation and ensures a thorough, balanced skill set.',
      image: elipse10,
      General_or_Specific:'General',
      index:6
    },
    {
      name: 'CV from Interview',
      DisplayName: 'CV from Interview',
      price: 100,
      description: 'Transform Your Resume with Our Interactive CV Service: During a concise interview, we\'ll create a CV that blends elegance and professionalism. Our unique format accentuates both your personal and technical skills, ensuring high visibility for AI recruitment tools. Benefit from our innovative feature to include a barcode linking to your digital Evior profile. This personalized method ensures your CV shines in the crowded job market.',
      image: elipse10,
      General_or_Specific:'General',
      index:4
    },
  ]);


  const handleToggle = (value, productIndex) => {
    console.log("Toggle value:", value, "Product Index:", productIndex);

    const updatedProducts = products.map((product) => {
        if (product.index === productIndex) {
            console.log("Updating product at index:", productIndex);
            return { ...product, General_or_Specific: value };
        }
        return product;
    });

    console.log("Updated Products:", updatedProducts);
    setProducts(updatedProducts);
};


    const demoGraphData = {
        labels: ["content", "clarity", "structure", "creativity"],
        scores: [65, 85, 50, 99],
      };
    
      const demoImprovements = {
        "content": "The answer lacks specific details about the technical problem encountered and the steps taken to address it.",
        "clarity": "The answer is unclear and difficult to understand. Please try to provide a clearer explanation.",
        "structure": "The answer lacks a clear structure and organization. Please organize the information in a more structured manner.",
        "creativity": "The solution is creative and original. You provided a unique solution to the problem.",
      };
    
      // Function to render the demo graph
      const renderDemoGraph = () => {
        const ctx = document.getElementById("demoGraph");
    
        if (Chart.getChart(ctx)) {
          Chart.getChart(ctx).destroy();
        }
    
        new Chart(ctx, {
          type: "bar",
          data: {
            labels: demoGraphData.labels,
            datasets: [
              {
                label: "Scores",
                data: demoGraphData.scores,
                backgroundColor: "rgba(224, 194, 188, 0.7)", // rgba color with alpha channel
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                max: 100,
                title: {
                  display: true,
                  text: "Scores",
                },
              },
            },
          },
        });
      };
    
      useEffect(() => {
        // Render the demo graph when the component mounts
        renderDemoGraph();
      }, []);


    return (
        <div className={styles.homepage}>
            <Helmet>
            <title>Evior - Revolutionize Your Career Journey</title>
            <meta name="description" content="Join Evior to discover a new way of getting hired. Tailored career opportunities await in our curated landscape. Start your journey with Evior today." />
            <script type="application/ld+json">
                {`
                  {
                    "@context": "http://schema.org",
                    "@type": "Organization",
                    "name": "Evior",
                    "url": "https://www.evior.com",
                    "logo": "https://www.evior.com/logo.png",
                    "contactPoint": {
                      "@type": "ContactPoint",
                      "telephone": "+1-800-123-4567",
                      "contactType": "Customer service"
                    }
                  }
                `}
                </script>
          </Helmet>
          <section className={styles.headerIMG}>
            <video autoPlay muted loop playsInline className={styles.videoBG}>
              <source src="https://d15nfymfajd7yw.cloudfront.net/content for website/Vidoes of metahuman/metahuma_IDLE1.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className={styles.header}>
                <p className={styles.headerTitle}>
                  Evior The New
                  <br />
                  Way To Get Hired
                </p>
                <p className={styles.headerContent}>
                  Prepare to Impress: Evior’s AI Coaching Refines
                  <br />
                  Your Skills, Guaranteeing Every Interview
                  <br />
                  is a Chance to Stand Out. Enjoy Evior's Customized Interview Training,  
                  <br />
                  Tailored Just for You!
                </p>
                <li className={styles.linkButton}>
                  <Link to="/Signup" className={`${styles.link} ${styles.signupButton}`}>Start Now!</Link>
                </li>
            </div>
          </section>

        <div className={styles.PageIMG}>
        <section className={styles.companycontienr1}>
              <p className={styles.companycontienrP}>We Helped Developers Get in to this companies</p>
            <div className={styles.companyLogosContiner}>
              <img className={styles.CompanyLogos} alt="Company Logo - AWS" src={aws} loading="Lazy"/>
              <img className={styles.CompanyLogos} alt="Company Logo - Tesla" src={Tesla} loading="Lazy"/>
              <img className={styles.CompanyLogos} alt="Company Logo - Cisco" src={cisco} loading="Lazy"/>
              <img className={styles.CompanyLogos} alt="Company Logo - InTel" src={intel} loading="Lazy"/>
              <img className={styles.CompanyLogos} alt="Company Logo - Google" src={google} loading="Lazy" />
            </div>
          </section>
          <section className={styles.demoVideoContiner2}>
            <p className={styles.demoVideoContiner2P}>Evior Platform AI in action</p> 
            <YouTube videoId="BErcHaCt5rA"/>
          </section>

          <section className={styles.PersonalizedInterviewContiner3}>
                <div className={styles.textContent}>
                    <p className={styles.PersonalizedInterviewTitle}>
                    Personalized Interview
                    <br />
                    Preparation for Job
                    <br />
                    Seekers
                    </p>
                    <p className={styles.PersonalizedInterviewContent}>
                    Prepare to excel in your next job interview,
                    <br />
                    Our AI-driven platform is expertly designed for personalized
                    <br />
                    HR and technical interview preparation, tailored specifically 
                    <br />
                    to the company you're targeting. Evior goes beyond basic practice,
                    <br />
                    it offers strategic preparation. You'll receive comprehensive feedback
                    <br />
                    and practical tips to refine  your skills and enhance your confidence,
                    <br />
                    ensuring your success in acing your dream job interview.
                    <br />
                </p>
                <li className={styles.linkButton}><Link to="/Signup" className={`${styles.link} ${styles.signupButton}`}>Start Now!</Link></li>
                </div>
                  <img className={styles.humephoto} alt="Backroud Image" src={humephotowithPinkBlure} loading="lazy" />
            </section>
            
            <section className={styles.ReceivepersonalizedfeedbackContiner4}>
            <div className={styles.PersonalizedInterviewTextContent}>
                    <p className={styles.ReceivepersonalizedfeedbackTitle}>
                        Make Every Interview Count
                        <br />
                        Get Personalized Feedback
                        <br />
                        for Peak Performance.
                    </p>
                    <p className={styles.ReceivepersonalizedfeedbackContent}>
                    Our feedback system analyzes your performance
                    <br />
                    and provides actionable
                    <br />
                     insights to help you improve your interview skills.
                </p>
                </div>
                <div className={graphstyle.container}>
                        <h2>Question </h2>
                        <p className={graphstyle.currentQuestiontext}>Hello John, as a Java specialist at Google, could you tell me about a specific project or accomplishment you're particularly proud of, and how Java played a crucial role in its success? </p>
                        <div className={graphstyle.graphcontainer}>
                            <canvas id="demoGraph" className="chart-container"></canvas>
                        </div>
                        <div className={graphstyle.improvementsContainer}>
                            <h3>Improvements:</h3>
                            {Object.entries(demoImprovements).map(([key, value]) => (
                                <p className={graphstyle.improvements} key={key}>
                                {key}: {value}
                                </p>
                        ))}
                    </div>
                </div>
            </section>
            <section className={styles.EviorIsNotJustContiner5}>
                <div className={styles.EviorIsNotJustTextContent}>
                    <p className={styles.EviorIsNotJustTitle}>
                      Evior is not just an Interview
                      <br />
                      Preparation tool
                      <br />
                      its much more!
                   </p>
                    <p className={styles.EviorIsNotJustContent}>
                      With Evior, the choice is yours to make your profile public to certified HR professionals at leading tech companies worldwide.
                      More than just an interview preparation tool,
                      Evior is a groundbreaking platform that revolutionizes the way you approach job hunting.
                      It's not just about preparing for interviews - it's about redefining the hiring process entirely.
                      Evior acts as a dynamic stage for your professional profile, making you easily discoverable to HR
                      professionals and recruiters. With our platform, you don't just seek jobs - you attract them. Welcome
                      to the future of job hunting with Evior. Experience the change, be the change
                  </p>
                <li className={styles.linkButton}><Link to="/Signup" className={`${styles.link} ${styles.signupButton}`}>Start</Link></li>
                </div>
                
                <div className={styles.EviorIsNotJustInerIMGS}>
                  <div className={styles.EviorIsNotJustInerIMG}>
                    <img className={styles.MignefingGlass} alt="ICON - magnifying glass" src={elipse1} loading="lazy"/>
                    <img className={styles.line} alt="Line" src={line2} loading="lazy" />
                  </div>
                  <div className={styles.EviorIsNotJustInerIMG}>
                    <img className={styles.MignefingGlass} alt="ICON - magnifying glass" src={elipse2} loading="lazy"/>
                    <img className={styles.line} alt="Line" src={line2} loading="lazy" />
                  </div>
                  <div className={styles.EviorIsNotJustInerIMG}>
                    <img className={styles.MignefingGlass} alt="ICON - magnifying glass" src={elipse3} loading="lazy"/>
                    <img className={styles.line} alt="Line" src={line2} loading="lazy" />
                  </div>
                </div>

                <div className={styles.EviorIsNotJustInerContiner}>
                <div className={styles.EviorIsNotJustInerTextContent}>
                <p className={styles.EviorIsNotJustInerTitle}>
                    Be Discovered
                    </p>
                    <p className={styles.EviorIsNotJustInerContent}>
                      Let recruiters find you. Your Evior profile
                      <br />
                      is your gateway to opportunities,
                      <br /> 
                      making you visible to the right people in the industry.
                    </p>
                </div>
                <div className={styles.EviorIsNotJustInerTextContent}>
                <p className={styles.EviorIsNotJustInerTitle}>
                    Save Time
                    </p>
                    <p className={styles.EviorIsNotJustInerContent}>
                      Say goodbye to endless interviews.
                      <br />
                      Evior aligns your profile with positions
                      <br />
                      that match your unique skills
                      and career aspirations, ensuring that every
                      <br />
                      interview is a step in the right direction.
                    </p>
                </div>
                <div className={styles.EviorIsNotJustInerTextContent}>
                <p className={styles.EviorIsNotJustInerTitle}>
                    Relax and Excel                    </p>
                    <p className={styles.EviorIsNotJustInerContent}>
                      With Evior, the stress of job searching is a thing of the past. We turn the tables, where companies
                      compete for you, not the other way around.
                    </p>

                </div>
                </div>
           </section>


           <section className={styles.productsSection6}>
           <div className={productsstyle.productsSection}>
              {products.map((product) => (
                <div key={product.index} className={productsstyle.productItem}>
                  <img src={product.image} alt="Product Image" className={productsstyle.productImage} loading="lazy" />
                  <h3>{product.DisplayName}</h3>
                  <p className={productsstyle.productText} >{product.description}</p>
                  <div>{product.index !== 4 && <ToggleSwitch onToggle={handleToggle} productIndex={product.index} />}</div>
                  <li className={styles.linkButton}><Link to={`/info?product=${product.index}&General_or_Specific=${product.General_or_Specific}`}   className={`${styles.link} ${styles.signupButton}`}>Start</Link></li>
                </div>
              ))}
            </div>
            </section>

            <section className={styles.EviorGenerolOrSpesificSection}>
                <div className={styles.EviorGenerolOrSpesificContiner}>
                    <p className={styles.EviorGenerolOrSpesificTitle}>
                    Empower Your Interview: Decide Between General Preparation or Company-Specific
                   </p>
                    <p className={styles.EviorGenerolOrSpesificContent}>

                    With Evior, you have the flexibility to tailor your interview preparation. Choose between a general interview, focused on your CV, or a company-specific interview that also incorporates the unique requirements of your chosen company. Opting for the latter enables you to tackle questions directly related to that specific organization, allowing for targeted preparation for your upcoming interview. This approach ensures you are not just prepared, but precisely aligned with the expectations and culture of the company you aspire to join.
                      <br />
                      Experience the Future of Job Hunting
                  </p>
                </div>
           </section>

            <section className={styles.HappyCustemersContiner}>
            <div className={styles.HappyCustemersTextContentandimg}>
                <img src={elipse19stairs} alt="Product Image" className={styles.StairsIMG} />
                <div className={styles.HappyCustemersTextContent}>
                    <p className={styles.HappyCustemersTitle}>Happy Customers</p>
                      <p className={styles.HappyCustemersContent}>
                          Read what our satisfied users and HR professionals have to say about Evior.
                      </p>
                  </div>
                </div>
                <div className={styles.HappyCustemersReviueContiner}>
                <div className={styles.HappyCustemersInerContiner}>
                <div className={styles.HappyCustemersStarts}>
                <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                </div>
                <p className={styles.HappyCustemersInerTitle}>
                  Evior helped me excel in my interviews.
                  <br />
                  Highly recommended!
                </p>
                <img className={styles.profileIMG} alt="Ellipse" src={elipse12} />
                <div className={styles.HappyCustemersInerContent} >Michael Naor</div>
                <div className={styles.HappyCustemersInerContent}>CTO ,Evior</div>
              </div>
              <div className={styles.HappyCustemersInerContiner}>
                <div className={styles.HappyCustemersStarts}>
                <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                </div>
                <p className={styles.HappyCustemersInerTitle}>
                  Evior helped me excel in my interviews.
                  <br />
                  Highly recommended!
                </p>
                <img className={styles.profileIMG} alt="Ellipse" src={elipse12} />
                <div className={styles.HappyCustemersInerContent} >Michael Naor</div>
                <div className={styles.HappyCustemersInerContent}>CTO ,Evior</div>
              </div>
                <div className={styles.HappyCustemersInerContiner}>
                  <div className={styles.HappyCustemersStarts}>
                  <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                  <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                  <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                  <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                  <img className={styles.star} alt="Star"  src={star} loading="lazy" />
                  </div>
                    <p className={styles.HappyCustemersInerTitle}>
                      Evior helped me excel in my interviews.
                      <br />
                      Highly recommended!
                    </p>
                  <img className={styles.profileIMG} alt="Ellipse" src={elipse12} />
                  <div className={styles.HappyCustemersInerContent} >Michael Naor</div>
                  <div className={styles.HappyCustemersInerContent}>CTO ,Evior</div>
                </div>
              </div>
            </section>
        </div>
        </div>
    );
}

export default App;