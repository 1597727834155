import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Editor from '@monaco-editor/react';
import { useLocation } from 'react-router-dom';
import styles from './styles_css/CodeEditor.module.css'; // Importing the styles
import { Helmet } from 'react-helmet';
import useApi from './useApi'; // Import the custom hook

function CodeEditor({ email }) {
  const [questions, setQuestions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showStartButton, setShowStartButton] = useState(true);
  const [isCodeProjectSection, setIsCodeProjectSection] = useState(false);
  const [codeProjectRequirements, setCodeProjectRequirements] = useState('');
  const [codeInput, setCodeInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { sendRequest} = useApi();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const interview_number = searchParams.get('param1');

  const postData = {};
  
  const start = async () => {
    setIsLoading(true);
    postData.time_start = Date.now();
    postData.email = email
    postData.interview_number = interview_number
    postData.only_check_token = true

    try {
      const response = await sendRequest('/check_token-first_question', postData);
      localStorage.setItem('access_token', response.data.access_token);
      const interview_index = response.data.interview_index
      axios.post('https://zz2m27yeclg7e5c3k4abbq4op40qfahu.lambda-url.eu-north-1.on.aws/', { email, interview_number}).then((response) => {
        setQuestions(response.data.questions);
        setIsLoading(false);
        setShowStartButton(false);
        CodeProject();
      });
    } catch (error) {
      setIsLoading(false);
      alert("error try again")
      console.error('Error sending POST request:', error);
      window.location.href = '/Dashboard';
    }
  };

  const handleOptionSelect = (questionIndex, option) => {
    setSelectedOptions({
      ...selectedOptions,
      [questionIndex]: option,
    });
  };

  const CodeProject = async () => {
    try {
    const response = await sendRequest('/code_project', postData);
      // Handle the code project response if needed
      const jsonObject = JSON.parse(response.data);
      // Update the codeProjectRequirements state with the content from the response
      setCodeProjectRequirements(jsonObject.Coding_Question);
    } catch (error) {
        alert("error try again")
        console.error('Error sending POST request:', error);
        window.location.href = '/Dashboard';
      }
    };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // All American questions are done, switch to the Code Project section
      setIsCodeProjectSection(true);

    }
  };

  const handleFinish = async () => {
    setIsLoading(true);
    const data = {
      questions: questions.map((question, index) => ({
        question: question.Question,
        selectedAnswer: selectedOptions[index],
        correctAnswer: question.CorrectAnswerLetter,
        options: {
          A: question.A,
          B: question.B,
          C: question.C,
          D: question.D,
        },
      })),
      codeProjectRequirements: codeProjectRequirements,
      codeInput: codeInput, // Include the code input in the data
    };
    
    const newData = {
      email: email,
      interview_number: interview_number,
      technical_test_data: data
    };
    try {
    const response = await sendRequest('/make_pdf_interview', newData);
    if (response) {  
    const redirectUrl = `interviuViewer?interview_number=${interview_number}`;
    window.location.href = redirectUrl;
    }else {         
      setIsLoading(false);
      alert("There has been an error try clicking finish again or contact support");
  }
  
    } catch (error) {
    console.log("error:",error)
      setIsLoading(false);
        alert("There has been an error try clicking finish again or contact support.")
    };
  };

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "Interactive Code Editor",
    "description": "Participate in interactive coding challenges and quizzes to test and enhance your programming skills.",
    "provider": {
      "@type": "Organization",
      "name": "Evior"
    }
    // Add more details relevant to your page content
  };

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Interactive Code Editor - Learn and Test Your Coding Skills</title>
        <meta name="description" content="Engage in an interactive coding experience, test your skills with real-time challenges, and enhance your programming knowledge." />
        <meta name="keywords" content="interactive coding, coding challenges, programming skills, code editor" />
        {/* Add other relevant meta tags */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div className={styles.codeEditor}>
        {showStartButton && !isCodeProjectSection && (
          <>
            <button className={styles.button} onClick={start} aria-label="Start Coding Challenge">Start</button>
            {isLoading && <div className={styles.loadingScreen}><div className={styles.loadingSpinner} aria-label="Loading"></div></div>}
          </>
        )}

        {currentQuestionIndex < questions.length && !isCodeProjectSection && (
          <div className={styles.question}>
            {questions[currentQuestionIndex].IsQuestionCodeQuestion ? (
              <div>
                <h3>Code Question:</h3>
                <Editor
                  className={styles.editor}
                  height="300px"
                  theme="vs-dark"
                  defaultLanguage="python"
                  value={questions[currentQuestionIndex].Question}
                  options={{ readOnly: true }}
                  aria-label="Code Question Editor"
                />
              </div>
            ) : (
              <h3>{questions[currentQuestionIndex].Question}</h3>
            )}
            <div>
              {['A', 'B', 'C', 'D'].map((option) => (
                <div key={option} className={styles.option}>
                  <input
                    type="radio"
                    name={`question${currentQuestionIndex}`}
                    value={option}
                    checked={selectedOptions[currentQuestionIndex] === option}
                    onChange={() => handleOptionSelect(currentQuestionIndex, option)}
                    aria-label={`Option ${option}`}
                  />
                  <label>{questions[currentQuestionIndex][option]}</label>
                </div>
              ))}
            </div>
            {currentQuestionIndex < questions.length && (
              <button className={styles.button} onClick={handleNextQuestion} aria-label="Next Question">Next Question</button>
            )}
          </div>
        )}

        {isCodeProjectSection && (
          <div className={styles.question}>
            <h3>Code Project Requirements:</h3>
            <p>{codeProjectRequirements}</p>
            <Editor
              className={styles.editor}
              height="300px"
              theme="vs-dark"
              defaultLanguage="python"
              value=""
              aria-label="Code Project Editor"
            />
            <button className={styles.button} onClick={handleFinish} aria-label="Finish Project">Finish</button>
            {isLoading && <div className={styles.loadingScreen}><div className={styles.loadingSpinner} aria-label="Loading"></div></div>}
          </div>
        )}
      </div>
    </div>
  );
}

export default CodeEditor;