import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import notFoundImage from './images/404.png';
import styles from './styles_css/NotFoundPage404.module.css'; // Import the styles

const NotFoundPage = () => (
  <div className={styles.container}>
    <Helmet>
      <title>404 - Page Not Found</title>
      <meta name="description" content="The page you are trying to access does not exist. Use the button below to return to the homepage." />
      <meta name="robots" content="noindex, follow" />
    </Helmet>
    <h1 className={styles.title}>404 - Not Found!</h1>
    <p className={styles.message}>Sorry, the page you are looking for does not exist.</p>
    <img src={notFoundImage} alt="Page Not Found" className={styles.notFoundImage} />
    <Link to="/" className={styles.button}>
      Go to Home
    </Link>
  </div>
);

export default NotFoundPage;