import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles_css/Footer.module.css';

// Import SVG icons (assuming you have these files)
import FacebookIcon from './images/facebook-svgrepo-com.svg';
import LinkedInIcon from './images/linkedin-svgrepo-com.svg';
import InstagramIcon from './images/instagram-logo-fill-svgrepo-com.svg';
import EmailIcon from './images/email-svgrepo-com.svg';
import PhoneIcon from './images/phone-svgrepo-com (1).svg';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContent}>
                <div className={styles.footerSection}>
                    <a href="https://www.facebook.com/evior"><img src={FacebookIcon} alt="Facebook" /></a>
                    <a href="https://www.twitter.com/evior"><img src={LinkedInIcon} alt="LinkedIn" /></a>
                    <a href="https://www.instagram.com/evior"><img src={InstagramIcon} alt="Instagram" /></a>
                </div>
                <div className={styles.footerSection}>
                    <ul>
                        <li><Link to="/about">About Us</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                        <li><Link to="/signup">Sign Up</Link></li>
                    </ul>
                </div>
                <div className={styles.footerSection}>
                    <p><strong>Email:</strong> contact@evior.com</p>
                    <p><strong>Phone:</strong> +123 456 7890</p>
                </div>
            </div>
            <div className={styles.footerBottom}>
                &copy; {new Date().getFullYear()} Evior | All Rights Reserved
            </div>
        </footer>
    );
};

export default Footer;