// AuthContext.js
import React from 'react';
import { createContext, useContext, useState } from 'react';
import axios from 'axios'; // Import axios here
import ReactGA from "react-ga4";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const APITOKEN = process.env.REACT_APP_API_KEY;

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL + '/' , // Replace with your server's URL
  });

  const logout = () => {
    axiosInstance.post('/Logout', {}, { withCredentials: true }) // Replace '/logout' with your actual logout endpoint
      .then(response => {
        console.log('Logged out:', response.data);
        // Clear all data in localStorage
        localStorage.clear();
        // Perform any other logout-related actions (e.g., additional redirects or state updates)
        setIsLoggedIn(false);
        window.location.href = '/'; // Replace with the desired URL
      })
      .catch(error => {
        console.error('Error during logout:', error);
        // Optionally handle errors
      });
  };


  const login = async (email, password) => {
    try {  
      if (!email || !password) {
        throw new Error('Please enter both email and password.');
      }
  
      const response = await axiosInstance.post('/login', { email, password },
      {
        headers: {
          'x-api-key': APITOKEN
        },
        withCredentials: true,
      
    });
      console.log(response)
      const { data } = response;
      console.log(data.access_token)
      if (data.message === 'Login successful') {
        // Store the token in local storage
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('role', data.role);
        localStorage.setItem('email', data.email);
        localStorage.setItem('picture', data.picture);
        localStorage.setItem('FirstName', data.FirstName);

        ReactGA.event({
          category: 'User',
          action: 'Login',
          label: 'Login Successful',
          role: data.role, // Custom dimension for User Role
        });

        // Update the isLoggedIn state
        setIsLoggedIn(true);
        
        const previousUrl = sessionStorage.getItem('previous_url');
        if (previousUrl && previousUrl != `/Login`) {
          // Use the previousUrl as needed, e.g., for redirection
          console.log("previousUrl:",previousUrl)
          window.location.href = previousUrl;
        } else {
          console.log("previousUrl:",'/dashboard')
        // Redirect the user to the dashboard or another page
        window.location.href = '/dashboard'; // Replace with the desired URL
        }

      } else {
        throw new Error('Invalid email or password.');
      }
    } catch (error) {


      ReactGA.event({
        category: 'User',
        action: 'Login Failure',
        label:  error.message || "Unknown error", // Sending just the error message or a custom code
      });

      if (error.response && error.response.status === 403) {
        const message = error.response.data.message;
        return message;
      }

      console.error('An error occurred during login:', error.message)
      return error.message;
    }
  };


  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};