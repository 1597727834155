import React, { useRef, useEffect, useState } from 'react';
import { WebRTCClient } from "@arcware/webrtc-plugin";

function WebRTCComponent() {
  const protocol = window.location.protocol;
  const host = window.location.host;
  const iframeHost = `${protocol}//${host}`;
  
  const sizeContainerRef = useRef(null);
  const containerRef = useRef(null);
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const [webrtcClient, setWebrtcClient] = useState();
  const [webrtcClientInit, setWebrtcClientInit] = useState(false);
  const [webrtcClientready, setWebrtcClientready] = useState(false);
  const [audioDataSent, setAudioDataSent] = useState(false);
  const [IsLoadedInIframe, setIsLoadedInIframe] = useState(false);

  const responseCallback = (message) => {
    console.log("message:", message);

    if (message === "finished") {
      console.log("message === finished")
      setAudioDataSent(false);
      window.parent.postMessage("finished", iframeHost);
    }
  };

  const handleSendCommands = (command) => {
    webrtcClient.emitUIInteraction(command);
  };

  const videoInitialized = () => {
    console.log("open");
    setWebrtcClientready(true);
    window.parent.postMessage("open", iframeHost);
  };

  const initializeWebRTC = () => {
    setWebrtcClientInit(true);
  };

  const playMediaAfterInteraction = () => {
    videoRef.current.play()
    audioRef.current.muted = false;
    audioRef.current.play();
  };

  useEffect(() => {

    setIsLoadedInIframe(window.top !== window.self);
    console.log(IsLoadedInIframe)
    setAudioDataSent(false);
    initializeWebRTC();
    
    if (webrtcClientInit) {
      const args = {
        address: process.env.REACT_APP_WEBRTC_ADDRESS,
        shareId: process.env.REACT_APP_WEBRTC_SHAREID,
        settings: {},
        sizeContainer: sizeContainerRef.current,
        container: containerRef.current,
        videoRef: videoRef.current,
        audioRef: audioRef.current,
        playOverlay: false,
        loader: () => {},
        applicationResponse: responseCallback,
        videoInitializeCallback: videoInitialized,
      };

      if (!webrtcClient) {
        setWebrtcClient(new WebRTCClient(args));
      }
    }

    if (webrtcClient) {
      console.log('WebRTC client is initialized. Do something here.');
    }

    if (webrtcClient && audioDataSent) {
      playMediaAfterInteraction();
    }

    // Listen for interaction within the iframe
    window.addEventListener("message", function (e) {
      if (e.origin !== iframeHost) return;
      const messageData = e.data;
      console.log("message received from parent iframe: " + messageData);
      if (messageData && messageData.type === 'audioData') {
        window.parent.postMessage("audioDataReceived", iframeHost);
        const audioBase64 = messageData.data;

        if (audioBase64 && !audioDataSent) {
          splitAndHandleAudioChunks(audioBase64);
        }
      }

      // If the iframe is interacted with, trigger media playback
      playMediaAfterInteraction();
    });
  
  }, [webrtcClientInit, webrtcClientready, webrtcClient]);

  function splitAndHandleAudioChunks(audioBase64) {
    if (webrtcClient && !audioDataSent) {
      const chunkSize = 130000;
      const chunks = [];

      for (let i = 0; i < audioBase64.length; i += chunkSize) {
        chunks.push(audioBase64.slice(i, i + chunkSize));
      }

      const totalChunks = chunks.length;

      for (let index = 0; index < chunks.length; index++) {
        const chunkInfo = {
          "64base": JSON.stringify({
            "chunkIndex": index + 1,
            "totalChunks": totalChunks,
            "64base": chunks[index]
          })
        };
        console.log(chunkInfo);

        if (!audioDataSent) {
          handleSendCommands(chunkInfo);
        }
        setAudioDataSent(true);
        window.parent.postMessage("showIframe", iframeHost);
      }
    }
  }

  const mediaStyle = {
    borderRadius: '10px', // Correct notation for border-radius in JavaScript
    width: '100vw', // Corrected to 'vw' for viewport width, or specify a fixed width
    height: '100vh' // Corrected property name and value
  };

  return (
    <div className="webrtc-component" style={mediaStyle} >
      <div ref={sizeContainerRef}>
        <div ref={containerRef}>
          <video ref={videoRef} />
          <audio id="audioRef" ref={audioRef} />
        </div>
      </div>
    </div>
  );
}

export default WebRTCComponent;
