import React, { useState } from 'react';
import styles from './compoents_css/General_or_Speecific_Switch.css'; // Your CSS module

const ToggleSwitch = ({ onToggle,productIndex}) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = () => {
        const newToggleState = !isChecked ? 'Specific' : 'General';
        setIsChecked(!isChecked);
        console.log(newToggleState, productIndex)
        onToggle(newToggleState, productIndex); // Pass the new state and product index
    };

    return (
        <label className="switch">
            <input type="checkbox" checked={isChecked} onChange={handleToggle} />
        <span className="slider"></span>
        </label>
    );
};

export default ToggleSwitch;