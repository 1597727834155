import React, { useState } from 'react';
import { useAuth } from './AuthContext'; // Import useAuth from your AuthContext
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import styles from './log_in.module.css';
import ShowIcon from './images/eye-password-show-svgrepo-com.svg';
import HideIcon from  './images/eye-password-hide-svgrepo-com.svg';
import { Helmet } from 'react-helmet';
import GoogleLoginButton from './components/GoogleAuth/GoogleLogInButten';

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth(); // Access the login function from AuthContext
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // Define a regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleLogin = async (e) => {
    setIsLoading(true); // Set isLoading to false in case of error
    e.preventDefault();

    if (!email || !password) {
      alert('Please enter both email and password.');
      setError('Please enter both email and password.');
      return;
    }

    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address.');
      setError('Please enter a valid email address.');
      return;
    }

    try {
      const error = await login(email, password);
      if (error) {
        if (error === 'Request failed with status code 401') {
          alert('Wrong email or password.');
        } else {
          alert(error); // Display other types of error messages
        }
        setError(error);
        setIsLoading(false); // Set isLoading to false in case of error
      }
    } catch (error) {
      if (error === 'Request failed with status code 401') {
        alert('Wrong email or password.');
      } else {
        alert(error); // Display other types of error messages
      }
      setError('An unexpected error occurred.');
      setIsLoading(false); // Set isLoading to false in case of error
      alert('An unexpected error occurred.');
    }
  };

  return (
    <div className={styles.LoginPage}>
        <Helmet>
          <title>Login - Evior</title>
          <meta name="description" content="Log in to your account to access our services and features." />
          {/* Additional meta tags as needed */}
        </Helmet>
      <div className={styles.container}>
      {isLoading && (
            <div className={styles.loadingScreen}>
              <div className={styles.loadingSpinner}></div>
            </div>
          )}
          <>
            <h1 className={styles.title}>Login</h1>
            <form onSubmit={handleLogin} aria-label="Login Form">
              <div className={styles.formGroup}>
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={styles.inputField}
                  required

                />
              </div>
              <div className={styles.formGroup}>
                <input
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={styles.inputField}
                  required

                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className={styles.togglePasswordButton}
                >
                  {showPassword ? <img src={HideIcon} alt="Hide password" /> : <img src={ShowIcon} alt="Show password" />}
                </button>
              </div>
              <div className={styles.ButtenCointer}>
              <button type="submit" className={styles.button}>Login</button>
                <div className={styles.googleLoginButtonWrapper}>
                <GoogleLoginButton/>
              </div>
            </div>
            </form>
            <Link to="/ForgotPassword" className={styles.link}>Forgot Password</Link>
          </>
      </div>
    </div>
  );
}

export default Login;