import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ProfilePictureUploader from './webcame_profile_capture';
import styles from './styles_css/InfoComponent.module.css'; // Adjust the path as necessary
import elipse10 from './static/img/ellipse-10.png'
import { Helmet } from 'react-helmet';
import useApi from './useApi'; // Import the custom hook
import { useLocation } from 'react-router-dom';
import ToggleSwitch from "./components/General_or_Speecific_Switch";

const InfoComponent = ({ email }) => {
  const headers = {
    'x-api-key': process.env.REACT_APP_API_KEY,
    'AuthorizationToken': localStorage.getItem('access_token')
  }
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const { sendRequest} = useApi();

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [interviewUrl, setinterviewUrl] = useState('');
  const [cvFile, setCvFile] = useState(null);
  const [jobDescriptionFile, setJobDescriptionFile] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState('product-selection');
  const [profileImage, setProfileImage] = useState(null);
  const [IsThereCVFile, setIsThereCVFile] = useState(false);
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [cvText, setCvText] = useState("");
  const [Demo, setDemo] = useState(true);
  const [jobDescriptionText, setJobDescriptionText] = useState('');
  const videoElement = useRef(null);
  const canvasElement = useRef(null);
  const hrTokens = useRef(null);
  const [selectionType, setSelectionType] = useState('General');
  const [products,setProducts] = useState([
    {
      name: 'Demo Product',
      price: 0,
      description: 'A demo description.',
      image: elipse10, // You might want to use a different image for the demo product
      index: 0, // Ensure the index is unique
      isDemo: true, // This property is used to filter out the demo product
      General_or_Specific:'General'
    },
    {
      name: 'One HR Interview',
      DisplayName: 'HR Interview',
      price: 9.99,
      description: ' Realistic HR Simulation Interviews: Immerse yourself in true-to-life HR interview scenarios that prepare you for a diverse array of questions and situations. Receive detailed feedback at the end of each session, providing valuable insights to enhance your preparation for your next interview.',
      image: elipse10,
      General_or_Specific:'General',
      index:1
    },
    {
      name: 'Technical Interview',
      DisplayName: 'Technical Interview',
      price: 9.99,
      description: 'Master technical interviews with our simulation sessions. Develop problem-solving skills and confidence under pressure. Tackle tough questions, demonstrating expertise and creativity. Benefit from detailed feedback, fine-tuning your performance for real-world success. Stand out in your next interview with enhanced professional competence.',
      image: elipse10,
      General_or_Specific:'General',
      index:2
    },
    {
      name: 'HR + Technical Interview',
      DisplayName: 'HR + Technical Interview',
      price: 100,
      description: 'Integrated Approach: Seamlessly combines HR and technical interview practice.Complete Preparation: Get ready for all aspects of your job interview in one comprehensive package.Benefit: Saves time and provides a well-rounded preparation experience.',
      image: elipse10,
      General_or_Specific:'General',
      index:3
    },
    {
      name: 'Technical Test',
      DisplayName: 'Technical Test',
      price: 25,
      description: 'Ace Your Technical Interviews: Prepare with our comprehensive practice tests, featuring American-style questions and coding projects. Tailor your experience with general tests or opt for company-specific simulations. Elevate your readiness and confidence for that crucial interview!',
      image: elipse10,
      General_or_Specific:'General',
      index:5
    },
    {
      name: 'Technical Interview + Technical Test',
      DisplayName: 'Technical Interview + Technical Test',
      price: 75,
      description: 'Dual-focused program combining technical test practice with interview simulations. Complete Readiness: Prepares you for every aspect of your technical interview journey. Advantage: Streamlines preparation and ensures a thorough, balanced skill set.',
      image: elipse10,
      General_or_Specific:'General',
      index:6
    },
    {
      name: 'CV from Interview',
      DisplayName: 'CV from Interview',
      price: 100,
      description: 'Transform Your Resume with Our Interactive CV Service: During a concise interview, we\'ll create a CV that blends elegance and professionalism. Our unique format accentuates both your personal and technical skills, ensuring high visibility for AI recruitment tools. Benefit from our innovative feature to include a barcode linking to your digital Evior profile. This personalized method ensures your CV shines in the crowded job market.',
      image: elipse10,
      General_or_Specific:'General',
      index:4
    },
  ]);

  const location = useLocation();


  useEffect(() => {

    window.scrollTo(0, 0);
  
    const queryParams = new URLSearchParams(location.search);
    const product_Index = queryParams.get('product');
  
    if (product_Index === 'Demo') {
      // Find the demo product in your products array
      const demoProduct = products.find(p => p.isDemo);

      setSelectedProduct(demoProduct);
      setCurrentPage('user-info');  

    } else if (product_Index) {
      const General_or_Specific = queryParams.get('General_or_Specific');
      console.log(General_or_Specific)
      // Convert product_Index to a number and find the matching product
      const Product = products.find(p => p.index === Number(product_Index));

      if (Product) {
        setSelectedProduct(Product);
        //Product.General_or_Specific = General_or_Specific
        setCurrentPage('user-info');
      }
    }

    fetchProfilePicture(email);
  }, []);

  const fetchProfilePicture = async (email) => {
    setIsImageLoading(true);
    try {
      const response = await sendRequest('/get-profile-picture', { "email": email });
        if (response && response.data) {
          console.log(response.data)
            setDemo(response.data.Demo);
            setProfilePictureUrl(response.data.picture ? response.data.picture : null);
            hrTokens.current = response.data.HR_tokens;
            if (response?.data?.CV_File){
              setIsThereCVFile(true);
              setPdfUrl(response.data.CV_File.file_id);
            }
        } else {
            setProfilePictureUrl(null);
        }
    } catch (error) {
        console.error('Error in fetchProfilePicture:', error);
        setProfilePictureUrl(null);
        if (error.request) {
          // The request was made but no response was received
          console.error("Request error:", error.request);
  }
    } finally {
        setIsImageLoading(false);
    }
};



const selectProduct = (product) => {
  setSelectedProduct(product);
  console.log("Product selected:", product);
};


const goToPreviousPage = () => {
  if (currentPage === 'user-info') {
    setCurrentPage('product-selection');
  }
};

  
const goToNextPage = (product) => {

  setSelectedProduct(product);
  console.log("Current Page:", currentPage);
  if (currentPage === 'product-selection' && product) {
    setCurrentPage('user-info');
  } else if (currentPage === 'user-info') {
    console.log("submitForm function called");
    submitForm(); // Automatically submit the form
  }
};

  
  const onCvFileSelected = (event) => {
    const file = event.target.files[0];
    setCvFile(file);
  
    if (!file.name.endsWith('.doc') && !file.name.endsWith('.docx')) {
      const pdfUrl = window.URL.createObjectURL(file);
      setPdfUrl(pdfUrl);
    } else {
      // Handle the case for .doc or .docx files
      setPdfUrl(null);
    }
  };
  
  const onJobDescriptionFileSelected = (event) => {
    const file = event.target.files[0];
    setJobDescriptionFile(file);
  };


  const handleToggle = (value, productIndex) => {
    console.log("Toggle value:", value, "Product Index:", productIndex);

    const updatedProducts = products.map((product) => {
        if (product.index === productIndex) {
            console.log("Updating product at index:", productIndex);
            return { ...product, General_or_Specific: value };
        }
        return product;
    });

    console.log("Updated Products:", updatedProducts);
    setProducts(updatedProducts);
};

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result.split(',')[1]);
      };
      reader.onerror = (error) => reject(error);
    });
  };


  const submitForm = async () => {
    if (selectedProduct) {
      setIsLoading(true);
      const allowedFileTypes = [
        'application/pdf',
        'application/msword',
        'text/plain',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];
      console.log(selectedProduct)

      const product_index = selectedProduct.index;
      const product_price = selectedProduct.price;

      if (email) {
        const jsonData = {
          name: name,
          email: email,
          product: selectedProduct.name,
          product_index: product_index,
          product_price: product_price,
          generol_or_spesific: selectedProduct.General_or_Specific,
          files: []
        };

        console.log(1)

        switch (selectedProduct.index) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
            if (selectedProduct.General_or_Specific === 'General' || selectedProduct.General_or_Specific === 'Specific') {
              if (IsThereCVFile && selectedProduct.index !=4 || cvFile && allowedFileTypes.includes(cvFile.type)) {
                if (cvFile) {
                const base64Data = await fileToBase64(cvFile);
                jsonData.files.push({
                  type: 'CV_File',
                  filename: cvFile.name,
                  data: base64Data
      
                });
              }
                } else if (cvText && cvText.trim() !== '') {
                // Convert CV text to Blob and then create a File
                const cvBlob = new Blob([cvText], { type: 'text/plain' });
                const cvFileFromText = new File([cvBlob], 'cv.txt', { type: 'text/plain' });
                const base64Data = await fileToBase64(cvFileFromText);
                jsonData.files.push({
                  type: 'CV_File',
                  filename: 'cv.txt',
                  data: base64Data
                });
                } else {
                setIsLoading(false); // Exiting the loading state
                alert('Invalid CV file or no file selected');
                return;
              }
            }
            console.log(2)

            if (selectedProduct.General_or_Specific === 'Specific') {
              if (jobDescriptionFile && allowedFileTypes.includes(jobDescriptionFile.type)) {
                const base64Data = await fileToBase64(jobDescriptionFile);
                jsonData.files.push({
                  type: 'JobDescriptionFile',
                  filename: jobDescriptionFile.name,
                  data: base64Data
                });
               } else if (jobDescriptionText && jobDescriptionText.trim() !== '') {
                // Convert job description text to Blob and then create a File
                const jobDescriptionBlob = new Blob([jobDescriptionText], { type: 'text/plain' });
                const jobDescriptionFileFromText = new File([jobDescriptionBlob], 'job_description.txt', { type: 'text/plain' });
                const base64Data = await fileToBase64(jobDescriptionFileFromText);
                jsonData.files.push({
                  type: 'JobDescriptionFile',
                  filename: 'job_description.txt',
                  data: base64Data
                });
               } else {
                setIsLoading(false); // Exiting the loading state
                alert('Invalid job description file or no file selected');
                return;
              }
            }
            break;
          default:
            setIsLoading(false); // Exiting the loading state
            alert('Invalid product index');
            return;
        }
        console.log(3)

        try {
          console.log("BEFORE SENDING-add_interview_to_mongo")
        const response = await sendRequest('/add_interview_to_mongo', jsonData);
            console.log('Post request successful:', response);

            const interviewId = response.data.interview_id;
            
            jsonData.interview_number = interviewId
            console.log("jsonData",jsonData)

            axios
              .post('https://uexba3hn5aswhfl52ityxbiep40hhgfj.lambda-url.eu-north-1.on.aws/', jsonData)
              .then((response) => {
                console.log('File upload successful:', response);

                const playerId = response.data.id;
                const continueInterview = 'no';

              // Check if selectedProduct.index is equal to 5
              if (selectedProduct.index === 5) {
                const interviewUrl = `/codeEditor?param2=${encodeURIComponent(name)}&param1=${encodeURIComponent(interviewId)}`;
                window.location.href = interviewUrl;
                // Redirect to the codeEditor URL
              } else {
                // Redirect to the interview URL
                const interviewUrl = `/ue5?param2=${encodeURIComponent(name)}&param1=${encodeURIComponent(interviewId)}`;
                window.location.href = interviewUrl;  
                }

              })
              .catch((error) => {
                setIsLoading(false); // Exiting the loading state
                console.error('Error:', error);
                alert('An error occurred during file upload.'); // Alerting the user
              });
          } catch (error) {
          setIsLoading(false); // Exiting the loading state
            console.error('Error:', error);
            alert('An error occurred while submitting the form.');
          };
      }
    }
  };

  return (
    <div className={styles.InfoPgae}>
      <Helmet>
            <title>Start and selecct Product - Evior</title>
            <meta name="description" content="Choose and customize the best interview preparation product for your needs." />
      </Helmet>
    <main className={styles.mainContainer}>
      <section>
        <h3 className={styles.selectedProductTitle}>Selected Product: {selectedProduct?.name}  -  {selectedProduct?.General_or_Specific}</h3>
      </section>

      <div className={styles.slideFormContainer}>
        {currentPage === 'product-selection' && (
          <div className={styles.slideFormPage}>
            <section className={styles.productsSection}>
            {products.filter(product => Demo || !product.isDemo) .map((product) => (
                <div key={product.index} className={styles.productItem}>
                  <img loading='lazy' src={product.image} alt="Product Image" className={styles.productImage} />
                  <h3>{product.name}</h3>
                  <p className={styles.productText} >{product.description}</p>
                  <div>{product.index !== 4 && product.index !== 0 && <ToggleSwitch onToggle={handleToggle} productIndex={product.index} />}</div>
                  <button onClick={() => {goToNextPage(product);}} 
                    className={styles.selectButton}>
                    Select
                  </button>
                </div>
              ))}
            </section>
          </div>
        )}

        {currentPage === 'user-info' && (
          <div className={styles.slideFormPage}>
            <div className={styles.centerContainer}>
              <section className={styles.userInfoSection}>
                <div id="user-info">
                  {selectedProduct && (
                    <div>
                      <label htmlFor="cvFileInput">Upload CV File:</label>
                      <input type="file" id="cvFileInput" onChange={onCvFileSelected} accept=".pdf,.doc,.docx,.txt" className={styles.fileInput} />
                      {selectedProduct.General_or_Specific === 'Specific' && (
                        <div>
                          <label htmlFor="jobDescriptionFileInput">Upload Job Description File:</label>
                          <input type="file" id="jobDescriptionFileInput" onChange={onJobDescriptionFileSelected} accept=".pdf,.doc,.docx,.txt" className={styles.fileInput} />
                          <p>OR</p>
                          <textarea placeholder="Enter your job description text here" value={jobDescriptionText} onChange={(e) => setJobDescriptionText(e.target.value)} rows="4" cols="50" className={styles.textArea}></textarea>
                        </div>
                      )
                      }
                      {selectedProduct && selectedProduct.index === 4 && (
                        <div>
                          <p>OR</p>
                          <textarea
                            className={styles.textArea}
                            placeholder="Enter your CV text here"
                            value={cvText}
                            onChange={(e) => setCvText(e.target.value)}
                            rows="4"
                            cols="50"
                            
                          ></textarea>
                        </div>
                      )}  
                    </div>
                  )}
                  {pdfUrl && <iframe src={pdfUrl} width="100%" height="600px" className={styles.iframe}></iframe>}
                  {isLoading && <div className={styles.loadingScreen}><div className={styles.loadingSpinner}></div></div>}
                </div>
              </section>

              <div className={styles.uploaderContainer}>
                <ProfilePictureUploader 
                  email={email}
                  base64ProfileImg={profilePictureUrl}
                />
              </div>
            </div>
          </div>
        )}
      {currentPage === 'user-info' && (
        <div className={styles.navigationButtons}>
          <button onClick={goToPreviousPage} className={styles.navButton}>Back</button>
          <button onClick={() => goToNextPage(selectedProduct)} className={styles.navButton}>Next</button>
        </div>
        )}  
      </div>
    </main>
    </div>
  );
};

export default InfoComponent;