import axios from 'axios';
import { useState } from 'react';
import { useAuth } from './AuthContext';

function useApi() {
    const [error, setError] = useState(null);

    // Use useAuth inside the custom hook
    const { logout } = useAuth();

    const sendRequest = async (endpoint, payload, options = {}) => {
        const headers =  {
            'x-api-key': process.env.REACT_APP_API_KEY,
            'AuthorizationToken': localStorage.getItem('access_token')
        };
    
        const axiosConfig = {
            headers: { ...headers, ...options.headers },
            withCredentials: true  // Add this line
        };
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${endpoint}`, payload, axiosConfig);
            return response;
        } catch (err) {
            setError(err);
            handleCommonErrors(err);
            throw err;
        }
    };
    

    const handleCommonErrors = (error) => {
        if (error.request && error.request.status === 0) {
            console.log("Handling potential CORS issue, inferred as 403");
        }
        // Additional common error handling as needed
    };

    return { sendRequest, error };
}

export default useApi;