import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import Chart from "chart.js/auto";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import HumeResultVisualization from "./hume";
import styles from './styles_css/interviuViewer.module.css'
import { Helmet } from 'react-helmet';
import useApi from './useApi'; // Import the custom hook

function ViewPageInterview({email,role}) {
  const isAdmin = role === 'admin';

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const interview_number = JSON.parse(queryParams.get('interview_number'));

  const [jsonData, setJsonData] = useState(null);
  const [currentPage, setCurrentPage] = useState("initial");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [codeProject, setCodeProject] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null); // Initialize with null
  const [jobDescriptionViewed, setJobDescriptionViewed] = useState(false); // Track if job description is viewed
  const [CV_Product4Viewed, setCVProduct4Viewed] = useState(false); // Track if job description is viewed
  const [CV_Resultproduct4Viewed, setCV_Resultproduct4Viewed] = useState(false); // Track if job description is viewed
  const [isJobDescriptionVisible, setIsJobDescriptionVisible] = useState(false);
  const [showClipsPage, setShowClipsPage] = useState(false);
  const [currentClipIndex, setCurrentClipIndex] = useState(0);
  const [start, setstart] = useState(true);
  const [Product, setProduct] = useState("");
  const [ProductIndex, setProductIndex] = useState("");
  const [currentDisplayedClipIndex, setCurrentDisplayedClipIndex] = useState(0);
  let clipsContent;
  let isAuthorized = false; // Declare isAuthorized at a higher scope
  const [JobDescriptionURL, setJobDescriptionURL] = useState(null); // Initialize with null
  const [CV_URL, setCV_URL] = useState(null); // Initialize with null
  const [Resul_URL, setResul_URL] = useState(null); // Initialize with null
  const { sendRequest} = useApi();

  useEffect(() => {
    const fetchData = async () => {
      if (start) {
        if (isAdmin) {
          email = queryParams.get('email');
        }
        const requestData = {
          interview_number: interview_number,
          email: email,
          SentFromCvEditor: false
        };
  
        try {
          const response = await sendRequest('/viue-page-interviue', requestData);
          const data = response.data;
          console.log(data);
          setJsonData(data);
          setstart(false);
          setProduct(data.interview_data.product);
          setProductIndex(data.interview_data.product_index);
  
          if (data.interview_data && data.interview_data.technical_test && data.interview_data.technical_test.Code_Project) {
            setCodeProject(data.interview_data.technical_test.Code_Project);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
  
    fetchData();
  }, []);
  
  useEffect(() => {

    renderGraphAndQuestions();
    
    if (jsonData?.interview_data?.questions && Product != "CV from Interview") {
      setShowClipsPage(true);
    }
  }, [currentPage, currentQuestionIndex,jsonData]);

  const handleQuestionsClick = () => {
    setCurrentPage("questions");
    setCurrentQuestionIndex(0); // Load the first question
  };

  const handleTechnicalTestClick = () => {
    setCurrentPage("technicalTest");
    setCurrentQuestionIndex(0);
  };

  const handleCodeProjectClick = () => {
    setCurrentPage("codeProject");
  };
  const handleClipsPageClick = () => {
    setCurrentClipIndex(0);
    setCurrentPage("clips");
  };

  const initiateDownload = (fileToGet) => {
    if (fileToGet === "job_description"){
      setPdfUrl(jsonData?.interview_data?.JobDescriptionFile?.file_id);
    }
    if (fileToGet === "CV_File"){
      setPdfUrl(jsonData?.interview_data?.CV_File?.file_id);
    }
    if (fileToGet === "results"){
      setPdfUrl(jsonData?.interview_data?.Results);
    }
  };

  const handleViewFile = (file_to_get) => {
      setCurrentPage("ViewFile");
      initiateDownload(file_to_get);

  };



  const handleReturnToInitialPage = () => {
    setCurrentPage("initial");
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const renderGraphAndQuestions = () => {
    const questions = jsonData?.interview_data?.questions;
    const questionIds = questions ? Object.keys(questions) : [];
  
    if (questionIds.length > 0) {
      const currentQuestionId = questionIds[currentQuestionIndex];
      const currentQuestion = questions[currentQuestionId];
  
      if (currentQuestion && currentQuestion.results && currentQuestion.results.scores) {
        renderGraph(
          currentQuestionId,
          currentQuestion.question,
          currentQuestion.results.scores,
          currentQuestion.results.improvements
        );
      }
    }
  
    // Check if the data includes the Code_Project section
    if (jsonData?.interview_data?.technical_test?.Code_Project) {
      const codeProjectResults = jsonData.interview_data.technical_test.Code_Project.results;
      if (codeProjectResults && codeProjectResults.scores) {
        renderGraph("Code_Project", "Code Project Evaluation", codeProjectResults.scores);
      }
    }
  };

  const renderGraph = (questionId, questionText, scores) => {
    const ctx = document.getElementById("questionChart"); // Move the declaration here
  
    if (Chart.getChart(ctx)) {
      Chart.getChart(ctx).destroy();
    }
  
    new Chart(ctx, {
      type: "bar",
      data: {
        labels: Object.keys(scores),
        datasets: [
          {
            label: "Scores",
            data: Object.values(scores),
            backgroundColor: "rgba(224, 194, 188, 0.7)", // rgba color with alpha channel
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            max: 100,
            title: {
              display: true,
              text: "Scores",
            },
          },
        },
      },
    });
  
    const questionTextElement = document.getElementById("questionText");
    if (questionTextElement) {
      questionTextElement.innerText = questionText;
    }
  };
  
  let content;
  if (currentPage === "questions") {
    const questions = jsonData?.interview_data?.questions;
    const questionIds = questions ? Object.keys(questions) : [];
  
    if (questionIds.length === 0) {
      content = <p>No questions available.</p>;
    } else if (currentQuestionIndex < 0 || currentQuestionIndex >= questionIds.length) {
      content = <p>Question not found.</p>;
    } else {
      const currentQuestionId = questionIds[currentQuestionIndex];
      const currentQuestion = questions[currentQuestionId];
  
      if (currentQuestion && currentQuestion.results && currentQuestion.results.scores) {
        renderGraph(
          currentQuestionId,
          currentQuestion.question,
          currentQuestion.results.scores
        );
      }
  
      const improvements = currentQuestion.results ? currentQuestion.results.improvements : {};
      
      content = (
        <div className={styles.container}>
        <h2>Question {currentQuestionId}</h2>
          <p className={styles.currentQuestiontext}>{currentQuestion.question}</p>
          <div className={styles.graphcontainer}>
            <canvas id="questionChart" className="chart-container"></canvas>
          </div>
          {ProductIndex !== 4 && (
              <div className={styles.improvementsContainer}>
                <h3>Improvements:</h3>
                {Object.entries(improvements).map(([key, value]) => (
                  <p className={styles.improvements} key={key}>
                    {key}: {value}
                  </p>
                ))}
              </div>
            )}
          <div>
            <button onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0} className={styles.button}>
              Previous
            </button>
            <button onClick={handleNextQuestion} disabled={currentQuestionIndex === questionIds.length - 1} className={styles.button}>
              Next
            </button>
          </div>
          <button onClick={handleReturnToInitialPage} className={styles.button} >Return to Initial Page</button>
        </div>
      );
    }
  
  } else if (currentPage === 'technicalTest') {
    const technicalTest = jsonData?.interview_data?.technical_test;
    const technicalQuestions = technicalTest ? technicalTest.American_Questions.questions : [];
  
    if (technicalQuestions.length === 0) {
      content = <p>No technical test questions available.</p>;
    } else if (currentQuestionIndex < 0 || currentQuestionIndex >= technicalQuestions.length) {
      content = <p>Question not found.</p>;
    } else {
      const currentTechnicalQuestion = technicalQuestions[currentQuestionIndex];
      const totalScore = technicalTest.American_Questions.total_score;
  
      content = (
          <div className={styles.container}>
          {/* Add a title for the Total Score */}
          <h3>Total Score</h3>
          
          {/* Display the total score as a visually pleasing progress bar */}
          <div className="progress-bar-container">
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: `${(totalScore / 100) * 100}%` }}
              >
                {totalScore} / 100
              </div>
            </div>
          </div>
  
          <h2>Technical Question {currentQuestionIndex + 1}</h2>
          <p className={styles.currentQuestiontext} >{currentTechnicalQuestion.question}</p>
  
          {/* Display options, correctAnswer, and selectedAnswer for the current question */}
          <div>
            <h3>Options:</h3>
            <ul>
              {Object.keys(currentTechnicalQuestion.options).map((option) => (
                <li className={styles.options} key={option}>
                  Option: {option}
                  {option === currentTechnicalQuestion.correctAnswer ? (
                    <span className={styles.CorrectAnswer}> (Correct Answer)</span>
                  ) : null}
                  {option === currentTechnicalQuestion.selectedAnswer ? (
                    <span className={styles.SelectedAnswer}> (Selected Answer)</span>
                  ) : null}
                </li>
              ))}
            </ul>
          </div>
  
          <div>
            <button onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0} className={styles.button}>
              Previous
            </button>
            <button onClick={handleNextQuestion} disabled={currentQuestionIndex === technicalQuestions.length - 1} className={styles.button}>
              Next
            </button>
          </div>
          <button onClick={handleReturnToInitialPage} className={styles.button}>Return to Initial Page</button>
        </div>
      );
    }
  
  } else if (currentPage === 'codeProject') {
    if (codeProject) {
      // Check if codeProject has results
      const codeProjectResults = codeProject.results;
      if (codeProjectResults && codeProjectResults.scores) {
        // Call renderGraph with codeProject data
        renderGraph("Code_Project", "Code Project Evaluation", codeProjectResults.scores);
      }
  
      content = (
        <div className={styles.container}>
        <h2>Code Project</h2>
          <p className={styles.project_description} >Project Description: {codeProject.project_description}</p>
          <Editor
            height="200px"
            theme="vs-dark"
            defaultLanguage="python" // Set the appropriate language
            defaultValue={codeProject.code}
            options={{
              readOnly: true,
              selectOnLineNumbers: true
            }}
          />
          <div className={styles.graphcontainer}>
            <canvas id="questionChart" className="chart-container"></canvas>
          </div>
      
          {/* Display improvements here */}
          {codeProjectResults && codeProjectResults.improvements && ProductIndex !== 4 &&(
            <div className={styles.improvementsContainer}>
              <h3>Improvements:</h3>
              {Object.entries(codeProjectResults.improvements).map(([key, value]) => (
                <p className={styles.improvements} key={key}>
                  {key}: {value}
                </p>
              ))}
            </div>
          )}
      
          <button onClick={handleReturnToInitialPage} className={styles.button}>Return to Initial Page</button>
        </div>
      );

    } else {
      content = <p>Code Project data is not available yet. Please wait for it to load.</p>;
    }
    
  } else if (currentPage === "clips") {

    function changeClip(next = true) {
      const questions = jsonData?.interview_data?.questions;
      const validClips = questions ? Object.values(questions).filter(q => q.Video?.VideoFileId && q.Video?.HumeResult) : [];
    
      if (next) {
        setCurrentClipIndex((prevIndex) => Math.min(prevIndex + 1, validClips.length - 1));
      } else {
        setCurrentClipIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      }
    }
  
    function extractEmailFromURL(url) {
      const parts = url.split('/');
      return parts[3]; // Adjust the index according to your URL structure
    }

    const questions = jsonData?.interview_data?.questions;
    const validClips = questions ? Object.values(questions).filter(q => q.Video?.VideoFileId && q.Video?.HumeResult) : [];
  
    if (validClips.length === 0) {
      clipsContent = <p>No video clips available.</p>;
    } else {
      const currentClip = validClips[currentClipIndex];
      const humeResultData = currentClip?.Video?.HumeResult;  
  
      const videoElements = validClips.map((clip, index) => {
        const videoURL = clip.Video.VideoFileId;
        const emailFromURL = extractEmailFromURL(videoURL);
        isAuthorized = emailFromURL === email; // Update isAuthorized for each iteration
  
        if (!isAuthorized) {
          return <p key={index}>Unauthorized access. Video not available.</p>;
        }
  
        return (
          <video
            key={index}
            controls
            style={{ display: index === currentClipIndex ? "block" : "none" }}
          >
            <source src={videoURL} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        );
      });
  
      clipsContent = (
        <div className={styles.container}>
          <div className={styles.videoContainer}>
              {videoElements}
          </div>
          {isAuthorized && humeResultData && (
            <div className={`${styles.humeVisualizationMargin}`}>
              <HumeResultVisualization humeResultData={humeResultData} />
            </div>
          )}
          <div>
            <button onClick={() => changeClip(false)} disabled={currentClipIndex === 0} className={styles.button}>
              Previous Clip
            </button>
            <button
              onClick={() => changeClip(true)}
              disabled={currentClipIndex === validClips.length - 1}
              className={styles.button}
            >
              Next Clip
            </button>
          </div>
          <button onClick={handleReturnToInitialPage} className={styles.button}>Return to Initial Page</button>
        </div>
      );
    }

  } else if (currentPage === "ViewFile") {
    content = (
      <div>
        <iframe src={pdfUrl} width="100%" height="600px"></iframe>
        <button onClick={handleReturnToInitialPage} className={styles.button}>
          Return to Initial Page
        </button>
      </div>
    );
    }

  else 
  {

    const questionsExist = jsonData?.interview_data?.questions;
    const technicalTestExist = jsonData?.interview_data?.technical_test;

    if (questionsExist && !technicalTestExist) {
      content = (
        <div className={styles.container}>
          <h1>Result</h1>

          {jsonData?.interview_data?.JobDescriptionFile && (
        <button className={styles.button} onClick={() => handleViewFile('job_description')}>
            View Job Description
        </button>
      )}
        {Product == "CV from Interview" && (
        <button className={styles.button} onClick={() => handleViewFile('CV_File')} >
         CV used in Interview
        </button>
      )}

      {Product == "CV from Interview" && jsonData?.interview_data?.Results &&(
        <button className={styles.button} onClick={() => handleViewFile('results')} >
         CV Results
        </button>
      )}


          {showClipsPage && (
        <button className={styles.button} onClick={handleClipsPageClick} >
          Clips from Interview
        </button>
      )}
          <button onClick={handleQuestionsClick} className={styles.button} >Questions</button>
        </div>
      );
    } else if (!questionsExist && technicalTestExist) {
      content = (
        <div className={styles.container}>
          <h1>Result</h1>
          {jsonData?.interview_data?.JobDescriptionFile && (
        <button className={styles.button} onClick={() => handleViewFile('job_description')}>
          View Job Description
        </button>
      )}

          <button onClick={handleTechnicalTestClick} className={styles.button}>Technical Test</button>
          <button onClick={handleCodeProjectClick} className={styles.button}>Code Project</button>
        </div>
      );
    } else if (questionsExist && technicalTestExist ) {
      content = (
        <div className={styles.container}>
          <h1>Result</h1>
          {jsonData?.interview_data?.JobDescriptionFile && (
        <button className={styles.button} onClick={() => handleViewFile('job_description')}>
           View Job Description
        </button>
      )}

          {showClipsPage && (
        <button className={styles.button} onClick={handleClipsPageClick} >
          Clips from Interview
        </button>
      )}
          <button onClick={handleQuestionsClick} className={styles.button}>Questions</button>
          <button onClick={handleTechnicalTestClick} className={styles.button}>Technical Test</button>
          <button onClick={handleCodeProjectClick} className={styles.button}>Code Project</button>
        </div>
      );
    } else {
      content = <p>No data available.</p>;
    }
  }

  return (
    <div  className={styles.App}>
      <Helmet>
        <title>View Interview Page - Insightful Analysis</title>
        <meta name="description" content="Explore detailed interview analysis with interactive data visualizations and code reviews." />
        <meta name="keywords" content="interview, analysis, coding, data visualization" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            "name": "View Interview Page",
            "description": "Explore detailed interview analysis with interactive data visualizations and code reviews."
          })}
        </script>
      </Helmet>
      {content}
      {clipsContent}
    </div>
  );
}

export default ViewPageInterview;