import React, { useState } from 'react';
import axios from 'axios';
import styles from './ForgotPassword.module.css'
import { Helmet } from 'react-helmet';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [step, setStep] = useState(1);
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const headers =  {
    'x-api-key': process.env.REACT_APP_API_KEY
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleTokenChange = (e) => {
    setToken(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleResetPassword = async () => {
    try {
      if (step === 1) {
        await axios.post(BASE_URL + '/forgot-password', { email },{headers:headers});
        setMessage('Password reset instructions sent to your email.');
        setStep(2);
      } else if (step === 2) {
        await axios.post(BASE_URL + '/check-reset-token', { email, token },{headers:headers});
        setMessage('Token is valid. You can now reset your password.');
        setStep(3);
      } else if (step === 3) {
        await axios.post(BASE_URL + '/reset-password', { email, token, newPassword },{headers:headers});
        setMessage('Password reset successfully.');
        window.location.href = '/login';
      }
    } catch (error) {
      setMessage('Error: ' + error.response.data.error);
    }
  };

  return (
    <div className={styles.ForgotPasswordPage}>
      <Helmet>
        <title>Forgot Password - Evior</title>
        <meta name="description" content="Reset your password easily and securely." />
        {/* Additional meta tags as needed */}
      </Helmet>

      <div className={styles.container}>
        <h1 className={styles.title}>Forgot Password</h1>
        {step === 1 && (
          <>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              className={styles.inputField}
              aria-label="Email"
            />
            <button onClick={handleResetPassword} className={styles.button}>Request Reset</button>
          </>
        )}
        {step === 2 && (
          <>
            <input
              type="text"
              placeholder="Enter the token"
              value={token}
              onChange={handleTokenChange}
              className={styles.inputField}
              aria-label="Token"
            />
            <button onClick={handleResetPassword} className={styles.button}>Check Token</button>
          </>
        )}
        {step === 3 && (
          <>
            <input
              type="password"
              placeholder="Enter your new password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              className={styles.inputField}
              aria-label="New Password"
            />
            <button onClick={handleResetPassword} className={styles.button}>Reset Password</button>
          </>
        )}
        <p className={styles.message}>{message}</p>
      </div>
    </div>
  );
}

export default ForgotPassword;