import React, { useState } from 'react';
import Dashboard from './Dashboard';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import { Helmet } from 'react-helmet'; // Import Helmet

const CandidateSearch = ({ email ,role }) => {
  const navigate = useNavigate(); // Use useNavigate hook

  // Otherwise, return the search input and button
  return (
    <>
      <Helmet>
        <title>Candidate Search - Your Company Name</title>
        <meta name="description" content="Search for candidates in our comprehensive database." />
      </Helmet>
      <Dashboard role={role} CandidateSearch={true}/>
    </>
  );
};

export default CandidateSearch;