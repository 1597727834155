import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styles from './styles_css/dashboard.module.css'; // Correct the import path
import linkedInIcon from './images/LinkedIn_icon.svg';
import pinkheader from './images/Group 33 pink.png';
import greenheader from './images/Group 33 green.png';
import { ReactComponent as CloseIcon } from './images/close-small-svgrepo-com.svg';
import userAvatarPlaceholder from './images/default-avatar-profile-icon-vector-social-media-user-image-700-205124837-removebg-preview.png'; // Import an avatar placeholder image
import { Helmet } from 'react-helmet';
import useApi from './useApi'; // Import the custom hook

const Dashboard = ({ email, responseData, role, CandidateSearch}) => {
  const isAdmin = role === 'admin';
  const { sendRequest} = useApi();

  const [dateFilter, setDateFilter] = useState('');
  const [filterCriteria, setFilterCriteria] = useState({ productType: '', isFinished: false });
  const [showUnfinished, setShowUnfinished] = useState(false);
  const [token, settoken] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [fullname, setFullname] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [jsonData, setjsonData] = useState(null);
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const videoElementRef = useRef(null);
  const [interviewData, setInterviewData] = useState([]);
  const [orders, setOrders] = useState([]); // New state for orders
  const [showOrders, setShowOrders] = useState(false); // State to control the visibility of order buttons
  const [selectedOrder, setSelectedOrder] = useState(null); // State to track the currently selected order
  const [profileJson, setProfileJson] = useState({
    USD_tokens: null,
    full_name: null,
  });
  const [dataforedit, setdataforedit] = useState({
    profileImage: null,
    fullName: null,
    linkedIn: null,
  });
  
  const [FinishedLoading, setFinishedLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');


  useEffect(() => {
    const fetchedToken = localStorage.getItem('access_token');
    settoken(fetchedToken);
    setUserEmail(email);
    console.log(CandidateSearch) 
    if (responseData) {
      processData(responseData); // Process the provided response data
    } else if (email) {
      fetchProfileData(email,fetchedToken ); // Fetch data if response data is not provided
    }
  }, [email, responseData]);

  const processData = (data) => {
    // Process and set the states based on responseData
    if (data.orders) {
      setOrders(data.orders);
    }
    if (data.picture) {
      setProfilePictureUrl(data.picture);
    }
    if (data.interview_data) {
      setInterviewData(data.interview_data);
    }
    if (data.CV_File && data.CV_File.file_id) {
      setjsonData(data.CV_File.file_id);
    }
    setProfileJson({
      USD_tokens: data.USD_tokens,
      full_name: data.full_name,
      companyName: data.companyName,
      HeadQuartersLocation: data.HeadQuartersLocation,
      RolePosition: data.RolePosition,
      companyWebsite: data.companyWebsite,
      linkedIn: data.linkedIn ? data.linkedIn : "https://www.linkedin.com/in/your-profile",
      PublicProfile: data.PublicProfile
  });
  };

  const fetchProfileData = async (userEmail) => {
    console.log("Fetched data")
    try {
      const response = await sendRequest('/get-profile-picture', { email: userEmail,CandidateSearch: CandidateSearch });
      if (response.data) {
        console.log(response.data)
        processData(response.data); // Process the API response
        setLoading(false);
        setFinishedLoading(true);
      }

      } catch (error) {
        setLoading(false);
        setProfilePictureUrl(null);
        setFullname(null);
        setPdfUrl(null);
        setjsonData(null);
        setSelectedInterview(null);
        setInterviewData([]);
        setOrders([]);
        setSelectedOrder(null);
        setProfileJson({ USD_tokens: null, full_name: null });

        console.error(error);  
        if (error.response) {
          // The server responded with a status code out of the range of 2xx
          console.error("Response error:", error.response);
          if (error.response.status === 404) {
              setError("User Not Found!");
          } else {
              setError("An error occurred");
          }
      }  else {
          // Something else happened in setting up the request
          console.error("Error:", error.message);
          setError("An unexpected error occurred");
      }
    }
};

  const initiateDownload = () => {
    if (pdfUrl) {
      setPdfUrl(null); // Hide the CV if it's already being displayed
      setShowOrders(false); // Also close the orders dropdown
      return;
    }
    setPdfUrl(jsonData)

  };


  const handleSubmit = () => {
    if (role === 'admin' || role === 'owner') {
      setError("");
      setFinishedLoading(false);
      setLoading(true);
      setProfilePictureUrl(null);
      setFullname(null);
      setPdfUrl(null);
      setjsonData(null);
      setSelectedInterview(null);
      setInterviewData([]);
      setOrders([]);
      setSelectedOrder(null);
      setProfileJson({ USD_tokens: null, full_name: null });
      
      fetchProfileData(userEmail);
    } else {
     
    }
  };


  const continueInterview = (interviewId) => {
    if (interviewId){
      const continueinterview = "yes";
      const interviewUrl = `/ue5?param2=${encodeURIComponent(continueinterview)}&param1=${encodeURIComponent(interviewId)}`;
      window.location.href = interviewUrl;

    }
    else{
      console.console.warn("no interviewId");
    }
  };

  const imageUrl = 'https://d15nfymfajd7yw.cloudfront.net/111/111-david-naor.jpg';

  const handleSelectInterview = (interview) => {
    setSelectedInterview(interview);
    setShowDropdown(false); // This will close the interviews dropdown
  };

  const filterInterviews = () => {
    return interviewData.filter(interview => {
      const isFinished = Boolean(interview.Results);
  
      // Common filter criteria for both admins and non-admins
      const matchesProductType = filterCriteria.productType ? interview.product === filterCriteria.productType : true;
      const matchesDate = dateFilter ? new Date(interview.time_start).toDateString() === new Date(dateFilter).toDateString() : true;
  
      if (isAdmin) {
        // For admins: exclude 'CV from Interview', show finished interviews, and apply date and product type filters
        return isFinished && matchesProductType && matchesDate && interview.product !== 'CV from Interview';
      }
  
      // Existing filter logic for non-admin users
      let matchesStatus = true;
  
      if (filterCriteria.isFinished) {
        matchesStatus = isFinished;
      } else if (showUnfinished) {
        matchesStatus = !isFinished;
      }
  
      return matchesProductType && matchesStatus && matchesDate;
    });
  };
  

const handleClose = () => {
setSelectedInterview(null);
};


  return (
    <div className={styles.DashboardPage}>
      <Helmet>
        <title>{fullname ? `Profile of ${fullname}` : 'User Dashboard'} - Evior</title>
        <meta name="description" content={fullname ? `View the profile of ${fullname}, including career history and skills.` : 'Access your user dashboard to manage your profile, view interviews, and more.'} />
      </Helmet>

    <main className={styles.headerContainer}>
    <div style={{ height: '20px' }}></div>

    {CandidateSearch && (
    <div className={styles.SearchContainer}>
        <input
          className={styles.inputField}
          type="email"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          placeholder="Enter candidate's email"
        />
        <button className={styles.searchButton} onClick={handleSubmit}>
          Search
        </button>
        {loading && (
              <div className={styles.loadingSpinner}></div>
            )}
        { <div className={styles.errorMsg}>{error}</div>}
      </div>
    )}

{(!CandidateSearch || (CandidateSearch && FinishedLoading)) && (
    <article  className={styles.container}>
      {userEmail  && (
        <>

          {profilePictureUrl ? (
            <img loading="lazy" src={profilePictureUrl} alt="Profile Picture" className={styles.profilePicture} />
          ) : (
            <img loading="lazy" src={userAvatarPlaceholder} alt="Profile Picture" className={styles.profilePicture} />
          )}

          <div className={styles.userFullname}>{profileJson.full_name}</div>
          <div className={styles.userEmail}>Email: {userEmail}</div>
          {!isAdmin && (
          <div className={styles.userUsdTokens}>Credit's $: {profileJson.USD_tokens}</div>
          )}
          
          {!isAdmin && !CandidateSearch &&(
            <div className={`${styles.statusIndicator} ${profileJson.PublicProfile ? styles.public : styles.private}`}>
                {profileJson.PublicProfile ? 'Public' : 'Private'}
            </div>
        )}


        {!CandidateSearch && isAdmin && (
          <div >
            <div>
              <p className={styles.userEmail}>Company: {profileJson.companyName}</p>
              <p className={styles.userEmail}>Role/Position: {profileJson.RolePosition}</p>
              <p className={styles.userEmail}>
                Company website:
                {profileJson.companyWebsite && (
                  <a href={profileJson.companyWebsite} target="_blank" rel="noopener noreferrer">
                    {profileJson.companyWebsite}
                  </a>
                )}
              </p>
              <p className={styles.userEmail}>Company Headquarters: {profileJson.HeadQuartersLocation}</p>
            </div>
          </div>
        )}
  {((!CandidateSearch && !isAdmin) || (CandidateSearch && isAdmin)) && (
          <button onClick={() => initiateDownload()} className={styles.commonButton}>View CV</button>
          )}
    <div>
    {((!CandidateSearch && !isAdmin) || (CandidateSearch && isAdmin)) && (
        <button className={styles.commonButton} onClick={() => setShowDropdown(!showDropdown)}>
          Interviews
        </button>
          )}
          {showDropdown && (
            <div className={styles.dropdownContent}>
              <div className={styles.Filter}>
=            <select onChange={(e) => setFilterCriteria({ ...filterCriteria, productType: e.target.value })}>
              <option value="">All Products</option>
              <option value="One HR Interview">One HR Interview</option>
              <option value="Technical Interview">Technical Interview</option>
              <option value="HR + Technical Interview">HR + Technical Interview</option>
              {!isAdmin && (
              <option value="CV from Interview">CV from Interview</option>
              )}
              <option value="Technical Test">Technical Test</option>
              <option value="Technical Interview + Technical Test">Technical Interview + Technical Test</option>
              {/* Add other product options here */}
            </select>
          
            {!isAdmin && (
            <label>
              <input 
                type="checkbox" 
                checked={filterCriteria.isFinished} 
                onChange={(e) => setFilterCriteria({ ...filterCriteria, isFinished: e.target.checked })} 
              />
              Finished Interviews
            </label>
          )}
            {!isAdmin && (

            <label>
            <input 
              type="checkbox" 
              checked={showUnfinished} 
              onChange={(e) => setShowUnfinished(e.target.checked)} 
            />
            Unfinished Interviews
          </label>
          )}

            <input 
            type="date" 
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)} 
          />
        </div>
              {filterInterviews().map((interview) => (
                <button key={interview.interview_num} onClick={() => handleSelectInterview(interview)} className={styles.interviewButton}>
                  Interview {interview.interview_num}
                </button>
              ))}
            </div>
          )}

      </div>  
          {selectedInterview && (

            <div className={styles.selectedInterview}>
            <CloseIcon onClick={handleClose} className={styles.closeIcon} />
              <h3>Selected Interview: {selectedInterview.interview_num}</h3>
              <p>Product: {selectedInterview.product}</p>
              <p>Time Start: {selectedInterview.time_start}</p>
              {!isAdmin && (<p>USD Tokens Spent: {selectedInterview.USD_tokens_spent}</p>)}
              {selectedInterview.Results && selectedInterview.interview_num !== 4 && (
                <Link 
                    to={{
                      pathname: "/interviuViewer",
                      search: `?interview_number=${selectedInterview.interview_num}` + (isAdmin ? `&email=${encodeURIComponent(userEmail)}` : '')
                    }}>
                    <button className={styles.resultsButton}>
                      Results
                    </button>
                </Link>
              )}
  

              {!selectedInterview.Results && !isAdmin &&(
                <button className={styles.continueButton} onClick={() => continueInterview(selectedInterview.interview_num)}>
                  Continue Interview
                </button>
              )}
  
  
              {selectedInterview.generate_resume_data && !selectedInterview.Results && !isAdmin && (
                <Link to={{ pathname: "/cveditor", search: `?interview_number=${selectedInterview.interview_num}` }}>
                  <button className={styles.cvEditorButton}>
                    Go to CV Editor
                  </button>
                </Link>
              )}
  
            </div>
          )}
          
        {!isAdmin && (
          <div>
            <button className={styles.commonButton} onClick={() => setShowOrders(!showOrders)}>
              My Orders
            </button>
  
            {showOrders && orders.length > 0 && (
              <div className={styles.dropdownContent}>
                {orders.map((order, index) => (
                  <button key={index} className={styles.orderBtn} onClick={() => setSelectedOrder(order)}>
                    Order {index + 1}
                  </button>
                ))}
  
                {selectedOrder && (
                  <div className={styles.orderDetails}>
                    <h4>Order Details:</h4>
                    <p>Order ID: {selectedOrder.id}</p>
                    <p>Status: {selectedOrder.status}</p>
                    <p>Name: {selectedOrder.name}</p>
                    <p>Order description: {selectedOrder.description}</p>
                    <p>Currency: {selectedOrder.currency}</p>
                    <p>Amount: {selectedOrder.amount}</p>
                    <p>Time of order: {selectedOrder.create_time}</p>
                  </div>
                )}
              </div>
            )}
          </div>
          )}

        {!isAdmin && (
          <Link 
              to={`/EditDashbored?profilePicture=${encodeURIComponent(profilePictureUrl)}&fullName=${encodeURIComponent(profileJson.full_name)}`}
              className={styles.editProfileButton} // Use the new button style
            >
              Edit Profile
            </Link>
              )}

          <a href={profileJson.linkedIn} target="_blank">
          <img  loading="lazy" src={linkedInIcon} alt="LinkedIn"  className={styles.linkedinimg} />
          </a>
        </>
      )}
      {pdfUrl && <iframe src={pdfUrl} width="100%" height="600px" className={styles.iframe}></iframe>}
    </article >
  )}
    </main >
    <div style={{ margin: '600px' }}></div>

    </div>
  );
};

export default Dashboard;