import React, { useState, useRef } from 'react';
import axios from 'axios';
import styles from './webcame_profile_capture.module.css';
import useApi from './useApi'; // Import the custom hook

const ProfilePictureUploader = ({ email, base64ProfileImg }) => {
  const [profileImage, setProfileImage] = useState(base64ProfileImg || null);
  const [showCamera, setShowCamera] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filename, setFilename] = useState('');
  const videoElement = useRef(null);
  const canvasElement = useRef(null);
  const { sendRequest} = useApi();

  const onFileSelected = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result); // This will contain the base64-encoded image data
        setFilename(file.name); // Use the input file's name as the filename
      };
      reader.readAsDataURL(file);
    }
  };

  const captureImage = () => {
    const video = videoElement.current;
    const canvas = canvasElement.current;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    stopCamera();
    setShowCamera(false);
    canvas.toBlob((blob) => {
      if (blob) {
        const fileType = 'image/png'; // Replace with the desired file type
        const capturedFilename = `captured_image.${fileType.split('/')[1]}`;
        const reader = new FileReader();
        reader.onloadend = () => {
          setProfileImage(reader.result);
          setFilename(capturedFilename); // Use the captured image filename
        };
        reader.readAsDataURL(blob);
      } else {
        console.error('Failed to capture image: Blob is null');
      }
    }, 'image/png');
  };

  const sendProfilePicture = () => {
    if (profileImage !== null && typeof profileImage === 'string') {
      setIsLoading(true);
      const pictureData = profileImage.split(',')[1]; // Remove the data URI prefix

      uploadProfilePicture(pictureData, email, filename);
    } else {
      console.log('Profile image not available');
    }
  };

  const uploadProfilePicture = async (pictureData, userEmail, filename) => {
    const data = {
      email: userEmail,
      picture: pictureData,
      filename: filename,
    };
    console.log(data)

    try {
    const response = await sendRequest('/upload-profile-picture-s3', data);
        console.log('Profile picture updated successfully:', response.data);
        setIsLoading(false);
      } catch (error) {
        
        console.error('Error updating profile picture:', error);
        setIsLoading(false);
      };
  };

  const startCamera = () => {
    setShowCamera(true);
    const video = videoElement.current;
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        video.srcObject = stream;
        video.play();
      })
      .catch((error) => {
        console.error('Error accessing camera:', error);
      });
  };

  const stopCamera = () => {
    const video = videoElement.current;
    const stream = video.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    video.srcObject = null;
  };

  const cancelCamera = () => {
    stopCamera();
    setShowCamera(false);
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Profile Picture</h3>
      <div>
        {profileImage && (
          <img src={profileImage} alt="Profile" className={styles.image} />
        )}
        <video ref={videoElement} style={{ display: showCamera ? 'block' : 'none' }} className={styles.video}></video>
        <canvas ref={canvasElement} className={styles.canvas}></canvas>
        {!profileImage && <p>No profile picture. Please upload one.</p>}
      </div>
      <div>
        <input type="file" onChange={onFileSelected} accept=".jpg, .jpeg, .png" className={styles.fileInput} />
        
        {!showCamera && (
          <button onClick={startCamera} className={styles.button}>Take Picture</button>
        )}

        {showCamera && (
          <>
            <button onClick={captureImage} className={styles.button}>Capture</button>
            <button onClick={cancelCamera} className={styles.button}>Cancel</button>
          </>
        )}

        {profileImage && (
          <button onClick={sendProfilePicture} disabled={isLoading} className={`${styles.button} ${isLoading ? styles.buttonDisabled : ''}`}>Upload</button>
        )}
      </div>
      {isLoading && <p className={styles.loading}>Uploading...</p>}
    </div>
  );
};

export default ProfilePictureUploader;