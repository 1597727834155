import React, { useState, useEffect, useRef } from "react";
import img from './images/856795.jpg';

const HumeResultVisualization = ({ humeResultData }) => {
  const [hoveredEmotion, setHoveredEmotion] = useState(null);
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerSize({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initialize size on first render

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const emotionPositions = {
    Confident: { x: 10, y: 10 },
    Calm: { x: 110, y: 40 },
    Excited: { x: 200, y: 80 },
    Confusion: { x: 400, y: 360 },
    Unsettled: { x: 370, y: 430 },
    Curious: { x: 80, y: 330 },
    Happy: { x: 220, y: 240 },
    // Add more categories as needed
  };

  const emotionColors = {
    Confident: "rgba(255, 215, 0, 0.3)",
    Calm: "rgba(255, 69, 0, 0.3)",
    Excited: "rgba(128, 255, 0, 0.3)",
    Confusion: "rgba(255, 0, 255, 0.3)",
    Unsettled: "rgba(255, 0, 0, 0.3)",
    Curious: "rgba(0, 255, 0, 0.3)",
    Happy: "rgba(255, 0, 0, 0.3)",
    // Add more colors as needed
  };

  const minAlpha = 0.2;


  const groupEmotions = (predictions) => {
    const emotionGroups = {
      Confident: ["Determination", "Realization", "Pride"],
      Calm: ["Calmness", "Contentment", "Satisfaction", "Relief", "Peace"],
      Excited: ["Excitement"],
      Confusion: ["Confusion"],
      Unsettled: ["Anxiety", "Awkwardness", "Boredom", "Contempt", "Craving", "Disappointment", "Disgust", "Distress", "Doubt", "Embarrassment", "Empathic Pain", "Envy", "Fear", "Guilt", "Horror", "Shame", "Surprise (negative)", "Tiredness"],
      Curious: ["Contemplation", "Interest"],
      Happy: ["Admiration", "Adoration", "Aesthetic Appreciation", "Amusement", "Awe", "Interest", "Joy", "Love", "Desire", "Entrancement", "Romance", "Triumph"],
    };

    const groupedEmotions = {};

    // Initialize the groupedEmotions object with default values
    Object.keys(emotionGroups).forEach((group) => {
      groupedEmotions[group] = { score: 0, count: 0 };
    });

    // Calculate the sum of scores for each group
    predictions.forEach((emotion) => {
      Object.keys(emotionGroups).forEach((group) => {
        if (emotionGroups[group].includes(emotion.name)) {
          groupedEmotions[group].score += emotion.score;
          groupedEmotions[group].count += 1;
        }
      });
    });

    // Calculate the average score for each group
    Object.keys(groupedEmotions).forEach((group) => {
      if (groupedEmotions[group].count > 0) {
        groupedEmotions[group].averageScore = groupedEmotions[group].score / groupedEmotions[group].count;
      } else {
        groupedEmotions[group].averageScore = 0;
      }
    });

    return groupedEmotions;
  };

  const getCurrentEmotionPosition = (groupedEmotions) => {
    let totalX = 0;
    let totalY = 0;
    let totalCount = 0;
    let maxScore = 0;
    let maxGroup = null;
  
    // Find the highest-scoring emotion group
    Object.keys(groupedEmotions).forEach((group) => {
      const score = groupedEmotions[group].averageScore;
  
      if (score > maxScore) {
        maxScore = score;
        maxGroup = group;
      }
  
      const position = emotionPositions[group];
      const count = groupedEmotions[group].count;
  
      totalX += position.x * score;
      totalY += position.y * score;
      totalCount += score * count;
    });
  
    if (maxGroup) {
      // Calculate the weighted average position for all emotion groups
      const averagePosition = {
        x: totalCount > 0 ? totalX / totalCount : 0,
        y: totalCount > 0 ? totalY / totalCount : 0,
      };
  
      // Increase the influence of the highest-scoring emotion
      const highScoreInfluenceFactor = 1.5; // Adjust this factor as needed
      const maxGroupPosition = emotionPositions[maxGroup];
      const currentPosition = {
        x: (averagePosition.x + highScoreInfluenceFactor * maxGroupPosition.x) / (1 + highScoreInfluenceFactor),
        y: (averagePosition.y + highScoreInfluenceFactor * maxGroupPosition.y) / (1 + highScoreInfluenceFactor),
      };
  
      return {
        x: currentPosition.x * containerSize.width / 500, // Adjust 500 to match original image width
        y: currentPosition.y * containerSize.height / 500, // Adjust 500 to match original image height
      };
    } else {
      return { x: 0, y: 0 };
    }
  };


  const createEmotionLabels = (groupedEmotions) => {
    return Object.keys(groupedEmotions).map((group) => {
      const position = emotionPositions[group];
      const scaledPosition = {
        x: position.x * containerSize.width / 500, // Adjust 500 to match original image width
        y: position.y * containerSize.height / 500, // Adjust 500 to match original image height
      };
      const alpha = minAlpha + (groupedEmotions[group].averageScore / 1.0) * (1 - minAlpha);
      const color = emotionColors[group];

      const labelStyle = {
        position: "absolute",
        left: scaledPosition.x + "px",
        top: scaledPosition.y + "px",
        color: "white",
        backgroundColor: color,
        opacity: alpha + 0.1,
        padding: "4px",
        borderRadius: "10px",
        zIndex: 2,
        border: "4px solid #f5f5dc",
        cursor: "pointer",
      };

      const tooltipStyle = {
        position: "absolute",
        backgroundColor: "black",
        color: "white",
        textAlign: "center",
        borderRadius: "6px",
        padding: "5px 10px",
        zIndex: 3,
        left: "100%", // Adjust this to position the tooltip
        top: "50%",
        transform: "translateY(-50%)",
        whiteSpace: "nowrap",
      };

      return (
        <div
          key={group}
          style={labelStyle}
          onMouseEnter={() => setHoveredEmotion(group)}
          onMouseLeave={() => setHoveredEmotion(null)}
        >
          {group}
          {hoveredEmotion === group && (
            <div style={tooltipStyle}>
              Score: {groupedEmotions[group].averageScore.toFixed(2)}
            </div>
          )}
        </div>
      );
    });
  };
  
  
  if (humeResultData.face && humeResultData.face.warning) {
    return <div>Error: {humeResultData.face.warning}</div>;
  }
  const groupedEmotions = groupEmotions(humeResultData.face?.predictions[0]?.emotions);
  const currentPosition = getCurrentEmotionPosition(groupedEmotions);

  return (
    <div className="hume-result-visualization" style={{ position: "relative", maxWidth: "100%", height: "auto" }} ref={containerRef}>
      <div className="image-container">
        <img
          src={img}
          alt="Emotion Map"
          className="background-map"
          style={{ width: "100%", height: "auto", borderRadius: "10px" }}
        />
      </div>
      {createEmotionLabels(groupedEmotions)}
      <div
        className="current-emotion-marker"
        style={{
          position: "absolute",
          left: currentPosition.x + "px",
          top: currentPosition.y + "px",
          color: "white",
          opacity: 0.9,
          padding: "8px",
          borderRadius: "70%",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
          zIndex: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        title="Your current emotion"
      >
        <span style={{ fontSize: "22px" }}>😊</span>
      </div>
    </div>
  );
};

export default HumeResultVisualization;