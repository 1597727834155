import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styles from './sighn_up.module.css'; // Import the CSS module
import ShowIcon from './images/eye-password-show-svgrepo-com.svg';
import HideIcon from  './images/eye-password-hide-svgrepo-com.svg';
import { Helmet } from 'react-helmet';
import GoogleLoginButton from './components/GoogleAuth/GoogleLogInButten';
import Confetti from 'react-confetti';

function Signup() {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const headers =  {
    'x-api-key': process.env.REACT_APP_API_KEY
  };
  const [showPassword, setShowPassword] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(true);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });



  const handleSubmit = async (e) => {
    e.preventDefault();


    const { first_name, last_name, email, password } = formData;
    if (!first_name || !last_name || !email || !password) {
      alert('Please fill in all required fields.');
      return;
    }
  
    // Check if email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    try {
      await axios.post( BASE_URL + '/signup', formData,{headers:headers});
      console.warn("User created");
      setSignupSuccess(true);
      //window.location.href = 'Login'; // Redirect to login or show a success message
    } catch (error) {
      alert('Sign up failed:', error);
      console.error('Error:', error);
    }
  };

  return (
    <div className={styles.SignupPage}>
      <Helmet>
        <title>Sign Up - Evior</title>
        <meta name="description" content="Join our platform today. Sign up to access our exclusive services and features." />
        {/* Additional meta tags as needed */}
      </Helmet>

      {signupSuccess && (
            <>
              <div className={styles.overlay}>
                <div className={styles.successMessage}>
                  🎉 Welcome, {formData.first_name} to Evior! We are thrilled to have you as our customer. Click 'Start' to explore our demo product🚀
                  <button onClick={() => window.location.href = '/info?product=Demo'} className={styles.button}>
                    Try Demo for Free 🎮
                  </button>
                </div>
                {signupSuccess && <Confetti className={styles.confettiContainer} />}
              </div>
            </>
          )}

      <div className={styles.container}>
        <h1 className={styles.title}>Sign Up</h1>
        <form onSubmit={handleSubmit} aria-label="Signup Form">
          <div className={styles.formRow}>
            <div className={styles.formGroupHalf}>
              <input
                type="text"
                placeholder="First Name"
                value={formData.first_name}
                onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                className={styles.inputField}
                required
              />
            </div>
            <div className={styles.formGroupHalf}>
              <input
                type="text"
                placeholder="Last Name"
                value={formData.last_name}
                onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                className={styles.inputField}
                required
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <input
              type="text"
              placeholder="LinkedIn Profile (Optional)"
              value={formData.linkedin}
              onChange={(e) => setFormData({ ...formData, linkedin: e.target.value })}
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type="text"
              placeholder="Email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className={styles.inputField}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              className={styles.inputField}
              required
            />
              <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className={styles.togglePasswordButton}
            >
              {showPassword ? <img src={HideIcon} alt="Hide password" /> : <img src={ShowIcon} alt="Show password" />}
            </button>
          </div>
          <div className={styles.ButtenCointer}>
                <button type="submit" className={styles.button}>Sign Up</button>
                <div className={styles.googleLoginButtonWrapper}>
                <GoogleLoginButton/>
              </div>
            </div>
        </form>
      </div>
    </div>
  );
}

export default Signup;
