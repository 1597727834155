import React, { Component } from 'react';
import Protect from './protect'; // Import the Protect component
import styles from './styles_css/pricing.module.css';
import elipse10 from './static/img/ellipse-10.png'
import { Helmet } from 'react-helmet'; // Import Helmet

class PricingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [
        {
          name: 'One HR Interview',
          price: 9.99,
          description: 'Personalized Practice: Tailor your practice sessions based on your CV or target a specific companys interview style.Real-world Scenarios: Experience realistic HR interview scenarios, preparing you for a variety of questions and situations. Benefit: Boosts confidence and provides insights on how to present your skills and experiences effectively.',
          image: elipse10
        },
        {
          name: 'Technical Interview',
          price: 50,
          description: 'Customized Sessions: Focus on technical questions relevant to your skill set, derived from your CV or a chosen company.Skill Enhancement: Hone your technical knowledge and problem-solving abilities.Benefit: Increases your chances of impressing potential employers with your technical acumen.',
          image: elipse10
        },
        {
          name: 'HR + Technical Interview',
          price: 100,
          description: 'Integrated Approach: Seamlessly combines HR and technical interview practice.Complete Preparation: Get ready for all aspects of your job interview in one comprehensive package.Benefit: Saves time and provides a well-rounded preparation experience.',
          image: elipse10
        },
        {
          name: 'CV from Interview',
          price: 100,
          description: 'Interactive CV Creation: Generate or enhance your CV through a brief interview session.Stylish and Professional: Your CV will highlight both personal and technical strengths in a compelling format.Added Feature: Include a personal profile and a barcode linking to your online resume showcasing practice interviews.Benefit: Makes your CV stand out to HR professionals, reducing the time to land job interviews.',
          image: elipse10
        },
        {
          name: 'Technical Test',
          price: 25,
          description: 'Tailored Testing: Receive technical tests designed specifically for your CV or target company.Skill Evaluation: Assess and improve your technical prowess.Key Benefit: Prepares you effectively for the technical assessment part of the interview process.',
          image: elipse10
        },
        {
          name: 'Technical Interview + Technical Test',
          price: 75,
          description: 'Tailored Testing: Receive technical tests designed specifically for your CV or target company.Skill Evaluation: Assess and improve your technical prowess.Key Benefit: Prepares you effectively for the technical assessment part of the interview process.',
          image: elipse10
        }

        // Add more products as needed
      ],
      cart: [],
      totalPrice: 0,
      creditAmount: 0,
    };
  }

  renderStructuredData() {
    return this.state.products.map(product => ({
      "@context": "http://schema.org/",
      "@type": "Product",
      "name": product.name,
      "image": [product.image],
      "description": product.description,
      "brand": "Your Brand",
      "offers": {
        "@type": "Offer",
        "priceCurrency": "USD",
        "price": product.price
      }
    }));
  }


  addToCart(product) {
    const selectedProduct = { ...product };
    this.setState(
      (prevState) => ({
        cart: [...prevState.cart, selectedProduct],
      }),
      () => this.calculateTotalPrice()
    );
  }

  removeFromCart(index) {
    this.setState(
      (prevState) => ({
        cart: prevState.cart.filter((_, i) => i !== index),
      }),
      () => this.calculateTotalPrice()
    );
  }

  addToCartWithCredit() {
    const creditProduct = {
      name: 'Credit',
      price: this.state.creditAmount,
    };
    this.setState(
      (prevState) => ({
        cart: [...prevState.cart, creditProduct],
        creditAmount: 0,
      }),
      () => this.calculateTotalPrice()
    );
  }

  calculateTotalPrice() {
    const totalPrice = this.state.cart.reduce((total, product) => total + product.price, 0);
    let discount = 0;

    if (totalPrice > 200) {
      discount = totalPrice * 0.15;
    } else if (totalPrice > 100) {
      discount = totalPrice * 0.1;
    }

    this.setState({
      totalPrice: totalPrice - discount,
    });
  }

  getDiscountPercentage() {
    if (this.state.totalPrice > 200) {
      return '15';
    } else if (this.state.totalPrice > 100) {
      return '10';
    } else {
      return '0';
    }
  }

  getDiscountColor() {
    if (this.state.totalPrice > 200) {
      return 'green';
    } else if (this.state.totalPrice > 100) {
      return 'blue';
    } else {
      return 'black';
    }
  }
  checkout() {
    
    if (this.state.cart.length === 0) {
      alert("Cart is empty");
      return; // Exit the function to prevent further execution
    }  
    // Check if the user is authenticated (replace with your authentication logic)
    const isAuthenticated = Protect;
    const credits = this.state.totalPrice;
    const discountAmount = this.getDiscountPercentage();
    const cartItems = this.state.cart; // Get the cart items as an array
    
    // Extract only the names from the cart items
    const cartItemNames = cartItems.map(item => item.name);
    
    if (isAuthenticated) {
      // Construct the URL with query parameters
      const checkoutUrl = `/checkout?credits=${encodeURIComponent(credits)}&discountAmount=${encodeURIComponent(discountAmount)}&cart=${encodeURIComponent(JSON.stringify(cartItemNames))}`;
    
      // Navigate to the constructed URL
      window.location.href = checkoutUrl;
    } else {
      // User is not authenticated, handle authentication as needed
      sessionStorage.setItem('/previous_url', `/checkout?credits=${encodeURIComponent(credits)}&discountAmount=${encodeURIComponent(discountAmount)}`, { cart: JSON.stringify(cartItemNames) });
      window.location.href = '/login';
      console.log('Redirect to the login page or handle authentication');
    }
  }

  render() {
    return (
      <div className={styles.pricingPage}>
        <Helmet>
          <title>Pricing - Evior</title>
          <meta name="description" content="Check out our pricing for various services including HR Interviews, Technical Interviews, and more." />
          <script type="application/ld+json">
            {JSON.stringify(this.renderStructuredData())}
          </script>
        </Helmet>

        <img
          className={styles.img}
          alt="Decorative Background"
          src="https://cdn.animaapp.com/projects/656fb61af61393768a5cfd60/releases/657368b514ce421528c96c87/img/ellipse-15.png"
          loading="lazy" 
        />
  
        <section className={styles.pricingContainer}>
          <h2>Pricing</h2>
          <div className={styles.productsContainer}>
            {this.state.products.map((product, index) => (
              <article key={index} className={styles.productCard}>
                <img loading="lazy" src={product.image} alt={`Image of ${product.name}`} className={styles.productimg} />
                <h3>{product.name}</h3>
                <p className={styles.productText} >{product.description}</p>
                <div>Price: ${product.price}</div>
                <button className={styles.button} onClick={() => this.addToCart(product)}>Add to Cart</button>
              </article>
            ))}
          </div>
  
          <aside className={styles.cartContainer}>
            <h2>Cart</h2>
            <ul>
              {this.state.cart.map((product, index) => (
                <li key={index}>
                  {product.name} - ${product.price}
                  <button className={styles.button} onClick={() => this.removeFromCart(index)}>Remove</button>
                </li>
              ))}
            </ul>
  
            <div className={styles.creditContainer}>
              <label htmlFor="creditAmount">Credit Amount:</label>
              <input
                type="number"
                id="creditAmount"
                value={this.state.creditAmount}
                onChange={(e) => this.setState({ creditAmount: Number(e.target.value) })}
                min="0"
                step="10"
                className={styles.inputField}
              />
              <button className={styles.AddCredittoCartbutton} onClick={() => this.addToCartWithCredit()}>Add Credit to Cart</button>
            </div>
  
            {this.state.totalPrice > 100 && (
              <div className={styles.discountContainer}>
                <p>Discount Applied: {this.getDiscountPercentage()}%</p>
              </div>
            )}
  
            <div style={{ color: this.getDiscountColor() }}>
              <p className={styles.totalPrice}>Total: ${this.state.totalPrice}</p>
            </div>
  
            <button className={`${styles.button} ${styles.checkoutButton}`} onClick={() => this.checkout()}>Checkout</button>
          </aside>
        </section>
  
        <img
          className={styles.bigimg}
          loading="lazy" 
          alt="Decorative Background"
          src="https://cdn.animaapp.com/projects/656fb61af61393768a5cfd60/releases/657368b514ce421528c96c87/img/rectangle-23.png"
        />
      </div>
    );
  }
  
  
}

export default PricingComponent;