import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import styles from './styles_css/Contact.module.css';
import useApi from './useApi'; // Import the custom hook

const Protect = ({ element }) => {
  const { sendRequest} = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [picture, setPicture] = useState('');
  const [firstName, setFirstName] = useState('');
  const [role, setRole] = useState('');
  const { logout } = useAuth();
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const Host = process.env.REACT_APP_LOCAL_OR_DEVELEPMENT

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem('access_token');
      const previousUrl = window.location.href;
      sessionStorage.setItem('previous_url', previousUrl);
  
      if (!token) {
        setIsLoading(false);
        // Use history for navigation
        //window.location.href = '/Login'
        return;
      }
  
      console.log(BASE_URL + '/protect');
      try {
        const response = await sendRequest('/protect');
        const { data } = response;
  
        // Set user data
        setEmail(data.email);
        setPicture(data.picture);
        setFirstName(data.FirstName);
        setRole(data.role);
        setIsLoading(false);
  
        // Store user data in local storage
        localStorage.setItem('email', data.email);
        localStorage.setItem('picture', data.picture);
        localStorage.setItem('FirstName', data.FirstName);
        localStorage.setItem('role', data.role);
  
        // Redirect logic based on role and previous URL
        if (previousUrl === `${Host}/CandidateSearch` && !(data.role === 'admin' || data.role === 'owner')) {
          // Use history for navigation
          //window.location.href = '/';
          return;
        }
  
        // Add additional redirection logic here if needed
  
      } catch (error) {
        logout();
        console.error('Error:', error);
        setIsLoading(false);
        // Use history for navigation
        //window.location.href = '/Login'
      }
    }
    fetchData()
  }, []);

  if (isLoading) {
    return <div className={styles.loadingScreen}><div className={styles.loadingSpinner}></div></div>;
  }

  return React.cloneElement(element, { email, picture, firstName, role });
};

export default Protect;
