import React, { useState } from 'react';
import styles from './styles_css/CompanySignup.module.css'; // Import CSS module
import ShowIcon from './images/eye-password-show-svgrepo-com.svg';
import HideIcon from  './images/eye-password-hide-svgrepo-com.svg';
import axios from 'axios';
import { Helmet } from 'react-helmet';

function CompanySignup() {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const headers =  {
    'x-api-key': process.env.REACT_APP_API_KEY
  };
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false); // New state for success message
  const [formData, setFormData] = useState({
  });

  // Handle form submission
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      await axios.post(BASE_URL  + '/companysignup', formData,{headers:headers});
      console.warn("User created");
      setIsLoading(false);
      setSuccess(true);
    } catch (error) {
      setIsLoading(false);
      alert('Sign up failed:', error);
      console.error('Error:', error);
    }    console.log('Form Data:', formData);
  };

  // Update form data
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className={styles.signupPage}>
        <Helmet>
            <title>Company Signup - Evior</title>
            <meta name="description" content="Register your company with us to find the best talents and grow together." />
            {/* Additional meta tags as needed */}
        </Helmet>
      <div className={styles.container}>
      {isLoading && (
            <div className={styles.loadingScreen}>
              <div className={styles.loadingSpinner}></div>
            </div>
          )}
      {success && (
          <div className={styles.successMessage}>
            <h3>Application Received</h3>
            <p>Dear {formData.companyName},</p>
            <p>Thank you for submitting your application. We are currently reviewing your information and will notify you once the verification process is complete.</p>
            <p>If you have any questions or need further assistance, feel free to contact us.</p>
            <p>Best Regards,</p>
            <p>Evior Team</p>
          </div>
        )}
        {!success && (
            <div>
        <h1 className={styles.title}>Company Signup</h1>
        <form onSubmit={handleSubmit} aria-label="Company Signup Form">
        <div className={styles.formRow}>
            <div className={styles.formGroupHalf}>
                <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                className={styles.inputField}
                required
                />
            </div>
            <div className={styles.formGroupHalf}>
                <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                className={styles.inputField}
                required
                />
            </div>
            </div>
          <input
            type="text"
            name="companyName"
            placeholder="Company Name"
            value={formData.companyName}
            onChange={handleChange}
            className={styles.inputField}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Work Email"
            value={formData.email}
            onChange={handleChange}
            className={styles.inputField}
            required
          />
          <input
            type="text"
            name="Position"
            placeholder="Role/Position"
            value={formData.Position}
            onChange={handleChange}
            className={styles.inputField}
            required
          />
          <input
            type="text"
            name="companyWebsite"
            placeholder="Company Website"
            value={formData.companyWebsite}
            onChange={handleChange}
            className={styles.inputField}
            required
          />
            <input
              type="text"
              placeholder="LinkedIn Profile (Optional)"
              value={formData.linkedin}
              onChange={(e) => setFormData({ ...formData, linkedin: e.target.value })}
              className={styles.inputField}
            />
          <input
            type="text"
            name="HeadQuartersLocation"
            placeholder="Headquarters Location (Country ,State and City)"
            value={formData.HeadQuartersLocation}
            onChange={handleChange}
            className={styles.inputField}
            required
          />
         <input
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            className={styles.inputField}
            required
          />
        <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className={styles.togglePasswordButton}
        >
            {showPassword ? <img src={HideIcon} alt="Hide password" /> : <img src={ShowIcon} alt="Show password" />}
         </button>

          <button type="submit" className={styles.button}>Sign Up</button>
        </form>
        </div>

          )}
      </div>
    </div>
  );
}

export default CompanySignup;