
import React, { useEffect ,useState} from "react";
import { Link } from 'react-router-dom';
import Chart from "chart.js/auto"; // Import Chart.js
import YouTube from 'react-youtube';


import Dalleimg from './static/img/dall-e-2023-12-04-22-52-1.png'
import aws from './static/img/rectangle-4.png'
import Tesla from './static/img/rectangle-5.png'
import cisco from './static/img/rectangle-6.png'
import intel from './static/img/rectangle-7.png'
import google from './static/img/rectangle-8.png'
import humephoto from './static/img/rectangle-9.png'
import bar from './static/img/rectangle-10.png'
import rectangle11 from './static/img/rectangle-11.png'
import video from './static/img/rectangle-17.png'
import elipse1 from './static/img/ellipse-1.png'
import elipse2 from './static/img/ellipse-2.png'
import elipse3 from './static/img/ellipse-3.png'
import elipse8 from './static/img/ellipse-8.png'
import elipse9 from './static/img/ellipse-9.png'
import elipse10 from './static/img/ellipse-10.png'
import elipse11 from './static/img/ellipse-11.png'
import elipse12 from './static/img/ellipse-12.png'
import elipse19stairs from './static/img/rectangle-19.png'
import star from './static/img/star-3.svg'
import RectangleImage from './static/img/rectangle-18-2.svg';
import line1 from './static/img/line-4.svg';
import line2 from './static/img/line-2.svg';
import line3Buttom from './static/img/line-5.svg';
import social1 from './static/img/set-of-social-media-icon-in-round-bakground-free-vector-fotor-20-1.png';
import social2 from './static/img/set-of-social-media-icon-in-round-bakground-free-vector-fotor-20-2.png';
import social3 from './static/img/set-of-social-media-icon-in-round-bakground-free-vector-fotor-20.png';
import logo from './static/img/rectangle-20.png';
import video1 from './images/metahuma_IDLE1.mp4';


import graphstyle from './styles_css/interviuViewer.module.css'
import styles from './styles_css/home.module.css'
import productsstyle from './styles_css/InfoComponent.module.css'

function Home() {

  const [products] = useState([
    {
      name: 'One HR Interview',
      price: 9.99,
      description: 'Personalized Practice: Tailor your practice sessions based on your CV or target a specific companys interview style.Real-world Scenarios: Experience realistic HR interview scenarios, preparing you for a variety of questions and situations. Benefit: Boosts confidence and provides insights on how to present your skills and experiences effectively.',
      image: elipse10
    },
    {
      name: 'Technical Interview',
      price: 50,
      description: 'Customized Sessions: Focus on technical questions relevant to your skill set, derived from your CV or a chosen company.Skill Enhancement: Hone your technical knowledge and problem-solving abilities.Benefit: Increases your chances of impressing potential employers with your technical acumen.',
      image: elipse10
    },
    {
      name: 'HR + Technical Interview',
      price: 100,
      description: 'Integrated Approach: Seamlessly combines HR and technical interview practice.Complete Preparation: Get ready for all aspects of your job interview in one comprehensive package.Benefit: Saves time and provides a well-rounded preparation experience.',
      image: elipse10
    },
    {
      name: 'CV from Interview',
      price: 100,
      description: 'Interactive CV Creation: Generate or enhance your CV through a brief interview session.Stylish and Professional: Your CV will highlight both personal and technical strengths in a compelling format.Added Feature: Include a personal profile and a barcode linking to your online resume showcasing practice interviews.Benefit: Makes your CV stand out to HR professionals, reducing the time to land job interviews.',
      image: elipse10
    },
    {
      name: 'Technical Test',
      price: 25,
      description: 'Tailored Testing: Receive technical tests designed specifically for your CV or target company.Skill Evaluation: Assess and improve your technical prowess.Key Benefit: Prepares you effectively for the technical assessment part of the interview process.',
      image: elipse10
    },
    {
      name: 'Technical Interview + Technical Test',
      price: 75,
      description: 'Tailored Testing: Receive technical tests designed specifically for your CV or target company.Skill Evaluation: Assess and improve your technical prowess.Key Benefit: Prepares you effectively for the technical assessment part of the interview process.',
      image: elipse10
    }
,
  ]);


    const demoGraphData = {
        labels: ["Question 1", "Question 2", "Question 3", "Question 4"],
        scores: [80, 90, 70, 85],
      };
    
      const demoImprovements = {
        "Question 1": "+10%",
        "Question 2": "+5%",
        "Question 3": "-3%",
        "Question 4": "+8%",
      };
    
      // Function to render the demo graph
      const renderDemoGraph = () => {
        const ctx = document.getElementById("demoGraph");
    
        if (Chart.getChart(ctx)) {
          Chart.getChart(ctx).destroy();
        }
    
        new Chart(ctx, {
          type: "bar",
          data: {
            labels: demoGraphData.labels,
            datasets: [
              {
                label: "Scores",
                data: demoGraphData.scores,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                max: 100,
                title: {
                  display: true,
                  text: "Scores",
                },
              },
            },
          },
        });
      };
    
      useEffect(() => {
        // Render the demo graph when the component mounts
        renderDemoGraph();
      }, []);


    return (
        <div className={styles.homepage}>
          <section className={styles.headerIMG}>
            <video autoPlay muted loop playsInline className={styles.videoBG}>
              <source src={video1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className={styles.header}>
              <p className={styles.headerTitle}>
                Evior The New
                <br />
                Way To Get Hired
              </p>
              <p className={styles.headerContent}>
                Join Evior, where your next career move awaits,
                <br />
                not in a sea of applications,
                <br />
                but in a curated landscape of opportunity.
              </p>
              <li className={styles.linkButton}>
                <Link to="/Signup" className={`${styles.link} ${styles.signupButton}`}>Start</Link>
              </li>
            </div>
          </section>

        <div className={styles.PageIMG}>
        <section className={styles.companycontienr1}>
        <p className={styles.companycontienrP}>We Helped Developers Get in to this companies</p>
        <div className={styles.companyLogosContiner}>
          <img className={styles.CompanyLogos} alt="Rectangle" src={aws} />
          <img className={styles.CompanyLogos} alt="Rectangle" src={Tesla} />
          <img className={styles.CompanyLogos} alt="Rectangle" src={cisco} />
          <img className={styles.CompanyLogos} alt="Rectangle" src={intel} />
          <img className={styles.CompanyLogos} alt="Rectangle" src={google} />
          </div>
          </section>
          <section className={styles.demoVideoContiner2}>
          <p className={styles.demoVideoContiner2P}>Evior Platform AI in action</p> 
          <YouTube videoId="BErcHaCt5rA"/>
          </section>

          <section className={styles.PersonalizedInterviewContiner3}>
                <div className={styles.textContent}>
                    <p className={styles.PersonalizedInterviewTitle}>
                    Personalized Interview
                    <br />
                    Preparation for Job
                    <br />
                    Seekers
                    </p>
                    <p className={styles.PersonalizedInterviewContent}>
                    Evior&#39;s AI-powered platform offers personalized
                    <br />
                    HR and technical interview preparation,providing
                    <br />
                    detailed feedback and improvement suggestions
                    <br />
                    to help job seekers excel in their interviews.
                    <br />
                    Our technology ensures that candidates receive
                    <br />
                    tailored guidance to enhance their skills
                    <br />
                     and increase their chances of success.
                </p>
                <li className={styles.linkButton}><Link to="/Signup" className={`${styles.link} ${styles.signupButton}`}>Start</Link></li>
                </div>
                  <div className={styles.pinkBlure1}></div>
                  <img className={styles.humephoto} alt="Rectangle" src={humephoto} />
            </section>
            
            <section className={styles.ReceivepersonalizedfeedbackContiner4}>
            <div className={styles.PersonalizedInterviewTextContent}>
                    <p className={styles.ReceivepersonalizedfeedbackTitle}>
                        Receive personalized feedback
                        <br />
                        and improvement suggestions
                        <br />
                        to excel in your interviews.
                    </p>
                    <p className={styles.ReceivepersonalizedfeedbackContent}>
                    Our feedback system analyzes your performance
                    <br />
                    and provides actionable
                    <br />
                     insights to help you improve your interview skills.
                </p>
                </div>
                <div className={graphstyle.container}>
                        <h2>Question </h2>
                        <p className={graphstyle.currentQuestiontext}>how many birds </p>
                        <div className={graphstyle.graphcontainer}>
                            <canvas id="demoGraph" className="chart-container"></canvas>
                        </div>
                        <div className={graphstyle.improvementsContainer}>
                            <h3>Improvements:</h3>
                            {Object.entries(demoImprovements).map(([key, value]) => (
                                <p className={graphstyle.improvements} key={key}>
                                {key}: {value}
                                </p>
                            ))}
                            </div>
                </div>
            </section>
            <section className={styles.EviorIsNotJustContiner5}>
                <div className={styles.EviorIsNotJustTextContent}>
                    <p className={styles.EviorIsNotJustTitle}>
                      Evior is not just a Interview
                      <br />
                      Preparation tool
                      <br />
                      its much more!
                   </p>
                    <p className={styles.EviorIsNotJustContent}>
                      Evior is much more then just a interview preparation tool ,Evior is a revolutionary platform transforming
                      the job-seeking journey. Here, we don&#39;t just prepare you for interviews; we redefine the entire hiring
                      process. Our platform serves as a dynamic showcase for your professional profile, easily accessible to HR
                      professionals and recruiters. With Evior, you shift from being a job seeker to a job attractor.
                      <br />
                      Experience the Future of Job Hunting
                  </p>
                <li className={styles.linkButton}><Link to="/Signup" className={`${styles.link} ${styles.signupButton}`}>Start</Link></li>
                </div>
                
                <div className={styles.EviorIsNotJustInerIMGS}>
                  <div className={styles.EviorIsNotJustInerIMG}>
                    <img className={styles.MignefingGlass} alt="Ellipse" src={elipse1} />
                    <img className={styles.line} alt="Line" src={line2} />
                  </div>
                  <div className={styles.EviorIsNotJustInerIMG}>
                    <img className={styles.MignefingGlass} alt="Ellipse" src={elipse1} />
                    <img className={styles.line} alt="Line" src={line2} />
                  </div>
                  <div className={styles.EviorIsNotJustInerIMG}>
                    <img className={styles.MignefingGlass} alt="Ellipse" src={elipse1} />
                    <img className={styles.line} alt="Line" src={line2} />
                  </div>
                </div>

                <div className={styles.EviorIsNotJustInerContiner}>
                <div className={styles.EviorIsNotJustInerTextContent}>
                <p className={styles.EviorIsNotJustInerTitle}>
                    Be Discovered
                    </p>
                    <p className={styles.EviorIsNotJustInerContent}>
                      Let recruiters find you. Your Evior profile
                      <br />
                      is your gateway to opportunities,
                      <br /> 
                      making you visible to the right people in the industry.
                    </p>
                </div>
                <div className={styles.EviorIsNotJustInerTextContent}>
                <p className={styles.EviorIsNotJustInerTitle}>
                    Save Time
                    </p>
                    <p className={styles.EviorIsNotJustInerContent}>
                      Say goodbye to endless interviews.
                      <br />
                      Evior aligns your profile with positions
                      <br />
                      that match your unique skills
                      and career aspirations, ensuring that every
                      <br />
                      interview is a step in the right direction.
                    </p>
                </div>
                <div className={styles.EviorIsNotJustInerTextContent}>
                <p className={styles.EviorIsNotJustInerTitle}>
                    Relax and Excel                    </p>
                    <p className={styles.EviorIsNotJustInerContent}>
                      With Evior, the stress of job searching is a thing of the past. We turn the tables, where companies
                      compete for you, not the other way around.
                    </p>

                </div>
                </div>
           </section>

           <section className={styles.productsSection6}>
           <div className={productsstyle.productsSection}>
              {products.map((product) => (
                <div key={product.index} className={productsstyle.productItem}>
                  <img src={product.image} alt="Product Image" className={productsstyle.productImage} />
                  <h3>{product.name}</h3>
                  <p>{product.description}</p>
                  <li className={styles.linkButton}><Link to="/Signup" className={`${styles.link} ${styles.signupButton}`}>Start</Link></li>
                </div>
              ))}
            </div>
            </section>

            <section className={styles.HappyCustemersContiner}>
            <div className={styles.HappyCustemersTextContentandimg}>
                <img src={elipse19stairs} alt="Product Image" className={styles.StairsIMG} />
                <div className={styles.HappyCustemersTextContent}>
                    <p className={styles.HappyCustemersTitle}>Happy Customers</p>
                    <p className={styles.HappyCustemersContent}>
                        Read what our satisfied users and HR professionals have to say about Evior.
                    </p>
                  </div>
                </div>
                <div className={styles.HappyCustemersReviueContiner}>
                <div className={styles.HappyCustemersInerContiner}>
                <div className={styles.HappyCustemersStarts}>
                <img className={styles.star} alt="Star"  src={star} />
                <img className={styles.star} alt="Star"  src={star} />
                <img className={styles.star} alt="Star"  src={star} />
                <img className={styles.star} alt="Star"  src={star} />
                <img className={styles.star} alt="Star"  src={star} />
                </div>
                <p className={styles.HappyCustemersInerTitle}>
                  Evior helped me excel in my interviews.
                  <br />
                  Highly recommended!
                </p>
                <img className={styles.profileIMG} alt="Ellipse" src={elipse12} />
                <div className={styles.HappyCustemersInerContent} >Michael Naor</div>
                <div className={styles.HappyCustemersInerContent}>CTO ,Evior</div>
              </div>
              <div className={styles.HappyCustemersInerContiner}>
                <div className={styles.HappyCustemersStarts}>
                <img className={styles.star} alt="Star"  src={star} />
                <img className={styles.star} alt="Star"  src={star} />
                <img className={styles.star} alt="Star"  src={star} />
                <img className={styles.star} alt="Star"  src={star} />
                <img className={styles.star} alt="Star"  src={star} />
                </div>
                <p className={styles.HappyCustemersInerTitle}>
                  Evior helped me excel in my interviews.
                  <br />
                  Highly recommended!
                </p>
                <img className={styles.profileIMG} alt="Ellipse" src={elipse12} />
                <div className={styles.HappyCustemersInerContent} >Michael Naor</div>
                <div className={styles.HappyCustemersInerContent}>CTO ,Evior</div>
              </div>
              <div className={styles.HappyCustemersInerContiner}>
                <div className={styles.HappyCustemersStarts}>
                <img className={styles.star} alt="Star"  src={star} />
                <img className={styles.star} alt="Star"  src={star} />
                <img className={styles.star} alt="Star"  src={star} />
                <img className={styles.star} alt="Star"  src={star} />
                <img className={styles.star} alt="Star"  src={star} />
                </div>
                <p className={styles.HappyCustemersInerTitle}>
                  Evior helped me excel in my interviews.
                  <br />
                  Highly recommended!
                </p>
                <img className={styles.profileIMG} alt="Ellipse" src={elipse12} />
                <div className={styles.HappyCustemersInerContent} >Michael Naor</div>
                <div className={styles.HappyCustemersInerContent}>CTO ,Evior</div>
              </div>
              </div>
            </section>
        </div>
        </div>

    );
}

export default Home;