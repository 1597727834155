import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './CVEditor.module.css';
import backgroundImage1GreenURL from './images/1.jpg';
import backgroundImage1BlueURL from './images/2.jpg';
import backgroundImage2AndUpGreenURL from './images/3.png';
import backgroundImage2AndUpBlueURL from './images/4.jpg';
import axios from 'axios';
import QRCode from 'react-qr-code';
import { Helmet } from 'react-helmet';
import useApi from './useApi'; // Import the custom hook

function CVEditor({ email }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const interview_number = JSON.parse(queryParams.get('interview_number'));
  const { sendRequest} = useApi();

  const [improvementData, setImprovementData] = useState({});
  const [selectedBackground, setSelectedBackground] = useState(backgroundImage1GreenURL);
  const [currentPage, setCurrentPage] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [profileName, setProfileName] = useState('');
  const [contactItems, setContactItems] = useState([
    { type: 'email', value: null },
    // Add other default items as needed
  ]);

  const [SelectedImageProfile, setSelectedImageProfile] = useState('');
  const [SelectedImageProfiletosend, setelectedImageProfiletosend] = useState('');
  const [showBarcode, setShowBarcode] = useState(true);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const barcodeData = '123456789';

  const getTextAreaClass = (currentPage) => {
    let classes = currentPage >= 1 ? `${styles.textarea} ${styles.textareaExpanded}` : styles.textarea;
    if (editMode) {
      classes += ` ${styles.textareaEditMode}`;
    }
    return classes;
  };

  useEffect(() => {
    if (email) {
      fetchProfileData(email);
    }
  }, [email]);

  const fetchProfileData = async (userEmail) => {
    try {
    const body = { "email": userEmail, "interview_number": interview_number,'SentFromCvEditor':true };
    const response = await sendRequest('/viue-Page-Interviue',body);
        console.log(response)
        if (response.data && response.data.picture) {
          setSelectedImageProfile(`data:image/jpeg;base64,${response.data.picture}`);
          setelectedImageProfiletosend(response.data.picture);
        }
        console.log(response.data);
        const jsonString = response.data.interview_data.generate_resume_data
        console.log(jsonString)
        setImprovementData(jsonString)
        setProfileName(response.data.full_name);
        setContactItems([
          { type: 'email', value: response.data.email || userEmail },
          // Add other items as needed
        ]);

        // Set isLoading to false once you have the data
        setIsLoading(false);
      } catch (error) {
      console.error(error);
        alert(error.response.data.error)
        window.location.href = '/dashboard';
        setIsLoading(false);
      };
  };

  const addItem = () => {
    if (editMode) {
      const newItem = { type: '', value: '' };
      setContactItems([...contactItems, newItem]);
    }
  };
  
  const removeItem = (index) => {
    if (editMode) {
      const newItems = [...contactItems];
      newItems.splice(index, 1);
      setContactItems(newItems);
    }
  };

  const sectionOrder = [
    "Summary/Objective",
    "Skills",
    "Work Experience",
    "Education",
    "Projects/Portfolio",
    "Additional Sections"
  ];

  const sectionsPerPage = 2;
  const sortedSections = sectionOrder.filter(section => improvementData[section] !== undefined);
  const pages = [];

  for (let i = 0; i < sortedSections.length; i += sectionsPerPage) {
    pages.push(sortedSections.slice(i, i + sectionsPerPage));
  }

  const handleBackgroundChange = (newBackground) => {
    setSelectedBackground(newBackground);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const saveChanges = () => {
    // Update profileName and profileEmail with the edited values
    setEditMode(false);
  };

  const handleTextChange = (section, newText) => {
    setImprovementData((prevData) => ({
      ...prevData,
      [section]: newText,
    }));
  };

  const switchProfilePicture = () => {
    // Trigger file input click to select a new profile picture
    document.getElementById('profilePictureInput').click();
  };

  const selectedBackgroundOption = () => {
    if (currentPage >= 1) {
      return selectedBackground === backgroundImage1GreenURL
        ? backgroundImage2AndUpGreenURL
        : backgroundImage2AndUpBlueURL;
    } else {
      return selectedBackground;
    }
  };

  const handleImageUpload = (event) => {
    const selectedFile = event.target.files[0];
  
    if (selectedFile) {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        // reader.result contains the Base64 string
        const fullBase64String = reader.result;
        // Split the string on comma and take the second part (actual Base64 data)
        const base64Data = fullBase64String.split(',')[1];  
        // Set the base64 string to your state
        setSelectedImageProfile(fullBase64String);
        setelectedImageProfiletosend(base64Data);
      };
  
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
  
      reader.readAsDataURL(selectedFile);
    }
  };

  const generatePDF = async () => {
    const apiUrl = 'https://9yamokjza5.execute-api.eu-north-1.amazonaws.com/test/Generate_Resume'; // Replace with your server URL
  
    // Create an object with all the data
    const data = {
      interview_number: interview_number, // Convert to string if needed
      email: email,
      name: profileName,
      color: selectedBackground === backgroundImage1GreenURL ? 'green' : 'blue',
      image_file: SelectedImageProfiletosend, // Ensure this is a string or a JSON-serializable format
      showBarcode: showBarcode,
      data: improvementData, // Assuming improvementData is already in a JSON-serializable format
      contactItems: contactItems
    };
    
    console.log(data)
    try {
      const response = await sendRequest('/Generate_Resume', data);
        console.log('PDF generation request successful',response);
        const redirectUrl = `/Dashboard`;
        window.location.href = redirectUrl;
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
  };
  

  const toggleBarcodeVisibility = () => {
    setShowBarcode(!showBarcode); // Step 3
  };

  const renderPageNavigation = () => (
    <div className={styles.pageNavigation}>
      <button onClick={() => setCurrentPage(Math.max(0, currentPage - 1))} disabled={currentPage === 0}
      className={styles.generateButton}>
        Previous Page
      </button>
      <span>{`Page ${currentPage + 1} of ${Math.ceil(sortedSections.length / 2)}`}</span>
      <button
        onClick={() => setCurrentPage(Math.min(currentPage + 1, Math.ceil(sortedSections.length / 2) - 1))}
        disabled={currentPage === Math.ceil(sortedSections.length / 2) - 1}
        className={styles.generateButton}
      >
        Next Page
      </button>
    </div>
  );

  return (
    <div className={styles.container}>
      <Helmet>
        <title>CV Editor - Your Company Name</title>
        <meta name="description" content="Create and customize your CV using our online editor." />
        <meta name="keywords" content="CV, resume, editor, professional, design" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "CV Editor",
              "description": "Create and customize your CV using our online editor."
            }
          `}
        </script>
      </Helmet>
      <div className={styles.orientationAlert}>
      <div className={styles.phone}></div>
        Please rotate your device!
      </div>
      <div className={styles.header}>
        <h1>CV Editor</h1>
      </div>

      {/* Conditionally render loading state */}
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className={styles.backgroundOptions}>
            <button
              className={`${styles.backgroundThumbnail} ${
                selectedBackground === backgroundImage1GreenURL ? styles.selected : ''
              }`}
              onClick={() => handleBackgroundChange(backgroundImage1GreenURL)}
            >
              <img
                src={backgroundImage1GreenURL}
                alt="Green CV"
                className={styles.thumbnailImage}
                loading='lazy'
              />
            </button>
            <button
              className={`${styles.backgroundThumbnail} ${
                selectedBackground === backgroundImage1BlueURL ? styles.selected : ''
              }`}
              onClick={() => handleBackgroundChange(backgroundImage1BlueURL)}
            >
              <img
                src={backgroundImage1BlueURL}
                alt="Blue CV"
                className={styles.thumbnailImage}
                loading='lazy'
              />
            </button>
          </div>
          <div className={styles.backgroundPreview} style={{ backgroundImage: `url(${selectedBackgroundOption()})` }}>
            <div className={styles.userFullnameHeader}>
              {editMode ? (
                <input
                  type="text"
                  value={profileName}
                  onChange={(e) => setProfileName(e.target.value)}
                  className={`${styles.profileInput} ${styles.circularProfileImage}`}
                />
              ) : (
                profileName
              )}
            </div>
            {currentPage === 0 && (
              <div>
                <div className={styles.profileImageContainer}>
                  <img
                    src={SelectedImageProfile instanceof Blob ? URL.createObjectURL(SelectedImageProfile) : SelectedImageProfile}
                    alt="Profile"
                    className={`${styles.profileImage} ${styles.circularProfileImage}`}
                    loading='lazy'
                  />
                  <div style={{ marginTop: '10px' }}></div>
                  {editMode && (
                    <button className={styles.generateButton} onClick={switchProfilePicture}>Change Profile Picture</button>
                  )}
                  <div className={styles.contactHeader}>Contacts</div>
                  <div className={styles.contactsSection}>
                  {contactItems.map((item, index) => (
                    <div key={index} className={styles.contactsItem}>
                      {editMode ? (
                        <>
                        <input 
                          type="text"
                          placeholder="Enter type (e.g., Phone, Email)"
                          className={styles.input}
                          value={item.type}
                          onChange={(e) => {
                            const newItems = [...contactItems];
                            newItems[index].type = e.target.value;
                            setContactItems(newItems);
                          }}
                        />
                        <input 
                          type="text" 
                          placeholder="Enter value"
                          className={styles.input}
                          value={item.value} 
                          onChange={(e) => {
                            const newItems = [...contactItems];
                            newItems[index].value = e.target.value;
                            setContactItems(newItems);
                          }} 
                          />
                          <button className={styles.button} onClick={() => removeItem(index)}>Remove</button>
                        </>
                      ) : (
                        <p>{`${item.type}: ${item.value}`}</p>
                      )}
                    </div>
                  ))}
                  {editMode && (
                    <button className={styles.addNewItemButton} onClick={addItem}>Add New Item</button>
                  )}
                </div>
                  {editMode && (

                  <button
                    className={styles.generateButton}
                    onClick={toggleBarcodeVisibility}
                    title={showBarcode ? 'Hide Barcode' : 'Show Barcode'} // Tooltip text
                  >
                    {showBarcode ? 'Hide Barcode' : 'Show Barcode'}
                  </button>
                   )}
                  {showBarcode && ( // Step 4 - Conditionally render the barcode
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '45vh' }}>
                      <QRCode value={barcodeData} size={160} />
                    </div>
                  )}

                </div>
              </div>
            )}
            <div className={styles.textContainer} >
              {pages[currentPage].map((section, index) => (
                <div key={section}>
                  <h2 className={index === 0 ? styles.firstSectionTextArea : ''}>{section}</h2>
                  <textarea
                    value={improvementData[section]}
                    onChange={(e) => handleTextChange(section, e.target.value)}
                    className={getTextAreaClass(currentPage)}
                    readOnly={!editMode}
                  />
                </div>
              ))}
            </div>
          </div>
          {/* Add an ID to the file input */}
          <input
            id="profilePictureInput"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
            aria-label="Profile Picture Upload"
          />
      <div className={styles.ButtensContainer} >
        {renderPageNavigation()}
        {editMode && (
          <button onClick={saveChanges} className={styles.generateButton}>
            Save
          </button>
        )}
        <button onClick={toggleEditMode} className={styles.generateButton}>
          {editMode ? 'Cancel' : 'Edit CV'}
        </button>                  
          <button className={styles.generateButton} onClick={generatePDF}>
            Generate PDF
          </button>
          </div>

        </>
      )}
    </div>
  );
}

export default React.memo(CVEditor);
