import { useEffect } from 'react';
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';

const PageTracker = () => {
    const location = useLocation();

    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID); // Replace with your actual tracking ID
    useEffect(() => {
      const userId = 155;
      ReactGA.set({
        userId: userId
      });

        console.log({'Path': location.pathname + location.search})
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search});
      }, []);
    

  return null; // This component doesn't render anything
};

export default PageTracker;