import React, { useState } from 'react';
import styles from './styles_css/Contact.module.css'; // Import the CSS module
import img from './images/micoolguccifu 4-02.png';
import { Helmet } from 'react-helmet';
import useApi from './useApi'; // Import the custom hook

function ContactPage() {
  const { sendRequest} = useApi();
  const [formData, setFormData] = useState({
    name: '',
    email: '', // Set the default email value here
    message: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any of the required fields are empty
    if (!formData.name || !formData.email || !formData.message) {
      alert('Please fill out all required fields.');
      return;
    }

    // Set loading state to true
    setIsLoading(true);

    try {
      // Simulate a delay (remove this in your actual code)
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Assuming you have an API endpoint to send the form data to
      const response = await sendRequest('/send_support_email', formData); 

      if (response) {
        // Handle success
        setIsSubmitted(true);
      } else {
        // Handle errors
        setError('Error submitting form data');
      }
    } catch (error) {
      setError('Error:', error);
    } finally {
      // Set loading state to false regardless of success or error
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.ContactPage}>
        <Helmet>
        <title>Contact Us - Evior</title>
        <meta name="description" content="Get in touch with us via our contact form. We're here to answer your queries and address your concerns." />
        {/* You can add more meta tags specific to this page */}
      </Helmet>
      <div className={styles.container}>
        <div className={styles.left}>
          <header>
              <h2>Contact Us</h2>
          </header>
            {isLoading ? (
            <div className={styles.loadingScreen}>
              <div className={styles.loadingSpinner}></div>
            </div>
          ) : isSubmitted ? (
            <p>Thank you for contacting us. We will get back to you shortly.</p>
          ) : (
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.formField}>
                <label htmlFor="name" className={styles.label}>Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className={styles.inputText} // Updated class name
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={styles.formField}>
                <label htmlFor="email" className={styles.label}>Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={styles.inputEmail} // Updated class name
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={styles.formField}>
                <label htmlFor="message" className={styles.label}>Message:</label>
                <textarea
                  id="message"
                  name="message"
                  className={styles.textarea} // Updated class name
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className={styles.button}>Submit</button>
            </form>
          )}
          {error && <p className={styles.error}>{error}</p>}
        </div>
        <div className={styles.right}>
          <img src={img} alt="Contact Image" className={styles.img} loading="lazy" />
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
