import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './styles_css/AboutPage.module.css';
import ButtenStyles from './styles_css/home.module.css';
import img1 from './images/Dalle about no backround.png';

const AboutPage = () => {
  return (
    <div className={styles.AboutPage}>
      <Helmet>
        <title>About Us - Your Company Name</title>
        <meta name="description" content="Learn more about our team and mission at Your Company Name. We are committed to delivering outstanding solutions and services." />
      </Helmet>

      <section className={styles.container}>
        <article className={styles.textSection}>
          <header>
            <h1>About Us</h1>
          </header>
          <p>Welcome to our website! We are a team dedicated to providing the best service in our industry. Our journey started several years ago with a mission to deliver outstanding solutions to our clients. We believe in innovation, excellence, and commitment to our customers. Stay a while and learn more about our story and what we have to offer.</p>
          <nav>
            <li className={ButtenStyles.linkButton}>
              <Link to="/pricing" className={`${ButtenStyles.link} ${ButtenStyles.signupButton}`}>Check our products</Link>
            </li>
          </nav>
        </article>

        <aside className={styles.imageSection}>
          <img src={img1} alt="Our Team"  loading="lazy"  />
        </aside>
      </section>
    </div>
  );
}

export default AboutPage;
